import actions from './actions';
import auth from '../auth';

const initialState = {
	alerts: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.getAlerts.REQUEST:
			return {
				...state,
				loading: true,
			};

		case actions.getAlerts.FAILURE:
			return {
				...state,
				loading: false,
			};

		case actions.getAlerts.SUCCESS:
			return {
				...state,
				alerts: payload.response.Alerts,
				loading: false,
			};
			
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
