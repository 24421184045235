import { fork, takeEvery, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BaseGetSagaHandler } from '@redux';
import { getNotifications, getBanners } from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl } from '@global';
import actions from './actions';

function* getNotificationsRequestSaga({ payload: { page, pageSize } }) {
	yield BaseGetSagaHandler({
		apiMethod: getNotifications,
		errorText: 'Get notifications',
		handler: actions.getNotifications,
	}, { payload: { currentPage: page, pageSize: pageSize } });
}

function* getBannersRequestSaga({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: getBanners,
		errorText: 'Get banners',
		handler: actions.getBanners,
	}, { payload });
}

function* showMessageModalRequestSaga() {
	const location = yield select(state => state.router.location);	
	yield put(push(addHashToUrl(location, ModalPopups.MESSAGE)));
}

function* hideMessageModal() {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, ModalPopups.MESSAGE)));
}

function* reloadNotifications() {
	const handler = yield select(state => state.notifications.reloadNotificationsHandler);

	yield call(handler);
}

function* watchGetNotificationsRequest() {
	yield takeEvery(actions.getNotifications.REQUEST, getNotificationsRequestSaga);
}

function* watchGetBannersRequest() {
	yield takeEvery(actions.getBanners.REQUEST, getBannersRequestSaga);
}

function* watchShowMessageModalRequest() {
	yield takeEvery(actions.SHOW_MESSAGE_MODAL, showMessageModalRequestSaga);
}

function* watchHideMessageModal() {
	yield takeEvery(actions.HIDE_MESSAGE_MODAL, hideMessageModal);
}

function* watchReloadNotifications() {
	yield takeEvery(actions.reloadNotifications.CALL_HANDLER, reloadNotifications);
}

// Root saga
const rootSaga = [
	fork(watchGetNotificationsRequest),
	fork(watchShowMessageModalRequest),
	fork(watchHideMessageModal),
	fork(watchReloadNotifications),
	fork(watchGetBannersRequest),
];

export default {
	rootSaga,
};
