import actions from './actions';

const initialState = {
	messageIsOpen: false,
};

function convertToISO(dateString) {
	return dateString;
	const match = /\/Date\((\d+)\)\//.exec(dateString);
	const res = match ? new Date(parseInt(match[1], 10)).toISOString() : dateString;
	console.log(dateString, res)
	return res;
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.SHOW_MESSAGE_MODAL:
			return {
				...state,
				messageIsOpen: true,
			};
		
		case actions.HIDE_MESSAGE_MODAL:
			return {
				...state,
				messageIsOpen: false,
			}

		case actions.getNotifications.SUCCESS:
			return {
				...state,
				lastLoadedNotifications: {
					unreadCount: payload.response.UnreadMessagesCount,
					notifications: payload.response.Notifications.map(x => ({...x, MessageDate: convertToISO(x.MessageDate) })),
					paginationState: {
						page: payload.response.Pagination.Page,
						pagesCount: payload.response.Pagination.PagesCount,
						pageSize: payload.response.Pagination.PageSize,
					}
				},
			};
		
		case actions.getBanners.SUCCESS:
			return {
				...state,
				banners: payload.response.Banners,
			}

		case actions.reloadNotifications.SET_HANDLER: 
			return {
				...state,
				reloadNotificationsHandler: payload.handler,
			}

		default:
			return state;
	}
};
