const actions = {
	// actions
	INIT: 'DASHBOARD/INIT',
	INIT_COMPLETED: 'DASHBOARD/INIT_COMPLETED',
	SIDEBAR_OPEN: 'DASHBOARD/SIDEBAR_OPEN',
	SIDEBAR_CLOSE: 'DASHBOARD/SIDEBAR_CLOSE',
	// action creators
	init: tradeCode => ({
		type: actions.INIT,
		payload: {
			tradeCode,
		},
	}),
	initComplete: payload => ({
		type: actions.INIT_COMPLETED,
		payload: {
			...payload,
		},
	}),
	sidebarOpen: () => ({
		type: actions.SIDEBAR_OPEN,
	}),
	sidebarClose: () => ({
		type: actions.SIDEBAR_CLOSE,
	}),
};

export default actions;
