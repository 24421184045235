import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import {
	FormCheckbox,
	FormRadioGroup,
	FormSelect,
	Divider,
	Button,
	Radio,
} from '@components';
import { R, FormInput, FormInputNumber, withTranslate } from '@dev2t/react-ui-kit/src/components';
import { checkLettersForLang } from '@global';
import { userAccounts, app } from '@redux';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '0px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	preFieldLabel: {
		margin: '10px 24px 0px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	staticText: {
		margin: '10px 24px 10px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	fieldLeftAdditionalMargin: {
		marginLeft: '24px',
	},
	summCurrency: {
		fontSize: '2em',
		fontWeight: '700',
		lineHeight: '60px',
	},
	mb: {
		marginBottom: '24px',
	},
});


const CY_WITHDRAWAL_CRYPTOWALLET_FORM_ID = 'CYWithdrawalCryptoWalletAmountForm';
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const currentAccountDisplayFullName = userAccounts.selectors.currentAccountDisplayFullName(state);
	const language = app.selectors.language(state);
	const EMoneyOut = formValueSelector(ownProps.form)(state, 'EMoneyOut') || ownProps.initialValues.EMoneyOut;

	return {
		EMoneyOut,
		currentTradeAccount,
		currentAccountDisplayFullName,
		language,
	};
};

class CYWithdrawalCryptoWalletAmountForm extends React.Component {

	static validate(formvalues, props) {

		const values = formvalues;
		const errors = {};
		if (!values) {
			return errors;
		}

		if (values.EMoneyOut) {
			errors.EMoneyOut = {};

			if (!values.EMoneyOut.Summ) {
				errors.EMoneyOut.Summ = 'required';
			}
			if (!values.EMoneyOut.TargetAccount) {
				errors.EMoneyOut.TargetAccount = 'required';
			}
			if (!values.EMoneyOut.DestinationTag) {
				errors.EMoneyOut.DestinationTag = 'required';
			}
			if (!values.EMoneyOut.Comment) {
				errors.EMoneyOut.Comment = 'required';
			}
		}
		if (values.NonOrderData) {
			errors.NonOrderData = {};
			if (!values.NonOrderData.ConfirmAccountOwner) {
				errors.NonOrderData.ConfirmAccountOwner = 'required';
			}
			if (!values.NonOrderData.ConfirmDataCorrect) {
				errors.NonOrderData.ConfirmDataCorrect = 'required';
			}
		}

		return errors;
	}

	render() {
		const {
			classes,
			handleSubmit,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			EMoneyOut,
			translate,
			...props
		} = this.props;


		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.inputItem}>
					<p className={classes.preFieldLabel}>
						<R id="moneyout_emoney_currency_title" defaultValue="Currency of your wallet/account/card" />
					</p>
					<div className={classes.fieldLeftAdditionalMargin}>
						<Field
							name="EMoneyOut.AccountCurrency"
							orientation="horizontal"
							component={FormRadioGroup}
						>
							<Radio value={EMoneyOut.AccountCurrency} label={EMoneyOut.AccountCurrency} />)
						</Field>
					</div>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.TargetAccount"
						label={translate('orders_crypto_targetaccount', 'Wallet Address')}
					/>
				</div>
				{EMoneyOut && EMoneyOut.SelectTarget === 'RIPPLE' &&
					<div className={classes.inputItem}>
						<Field
							component={FormInput}
							name="EMoneyOut.DestinationTag"
							label={translate('funds_deposit_payment_ripple_address_tag', 'Destination tag')}
						/>
					</div>
				}
				<div className={classes.inputItem}>
					<div className={classes.staticText}>
						<R
							id="shared_cryptocurrency_operations_warning"
							defaultValue="Note that transactions with cryptocurrency are irreversible. Be careful when specifying addresses of cryptocurrency wallets for transfers. Use coping instead of typing. Just2Trade does not bear any responsibility in case of an error in the address specified when transferring."
						/>
					</div>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.Comment"
						label={translate('shared_comment', 'Comment')}
						multiline
					/>
				</div>
				<div className={classNames(classes.inputItem, classes.mb)}>
					<Box display="flex" alignItems="center">
						<Field
							component={FormInputNumber}
							name="EMoneyOut.Summ"
							placeholder={translate('common_enter_the_amount_here', 'Enter the Amount here')}
							label={translate('moneyout_amount', 'Transfer amount')}
							allowIconButtons={false}
							prefix=""
						/>
						<div className={classes.summCurrency}>
							{EMoneyOut.Currency}
						</div>
					</Box>
				</div>

				<div className={classes.inputItem}>
					<Field
						component={FormCheckbox}
						name="NonOrderData.ConfirmAccountOwner"
						label={translate(
							'common_confirm_account_owner',
							'I confirm that the monetary funds shall be transferred to my personal bank account.'
						)}
					/>
				</div>
				<div className={classNames(classes.inputItem, classes.mb)}>
					<Field
						component={FormCheckbox}
						name="NonOrderData.ConfirmDataCorrect"
						label={translate(
							'common_moneyout_confirm_data_correct',
							'I confirm that all the details specified in the order are true.',
						)}
					/>
				</div>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={props.invalid || props.submitting}
				>
					{props.submitting ?
						<CircularProgress
							style={{ color: theme.palette.common.white }}
							size={18}
						/>
						: <R id="common_btn_continue" defaultValue="Continue" />
					}
				</Button>
			</form>
		);
	}
}

CYWithdrawalCryptoWalletAmountForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	withTranslate,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_WITHDRAWAL_CRYPTOWALLET_FORM_ID,
		validate: CYWithdrawalCryptoWalletAmountForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(CYWithdrawalCryptoWalletAmountForm);
