import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups } from '@enums';
import { changeHashInUrl, removeHashFromUrl, removeHashesFromUrl } from '@global';
import {
	app,
	depositFunds,
	signature,
	notifications,
	withdrawFunds,
} from '@redux';
import {
	CreateApiKeyModal,
	CommissionFreeModal,
	ConfirmModal,
	AddBankAccountModal,
	PlaidSelectAccountModal,
	CYNonTradingOrderModals,
	CYTransferBetweenAccountsModal,
	CYWithdrawalBankTransferModal,
	CYWithdrawalCryptoWalletModal,
	CYWithdrawalIndonesianBankModal,
	CYWithdrawalKoreanBankModal,
	CYWithdrawalMalaysianBankModal,
	CYWithdrawalKenyanBankModal,
	CYWithdrawalNigerianBankModal,
	CYWithdrawalIndianBankModal,
	DepositFundsAchModal,
	DepositFundsBrokerModal,
	DepositFundsWireModal,
	J2tLiteModal,
	J2tPlusModal,
	J2tProModal,
	Mt5Modal,
	StProModal,
	MessageModal,
	WithdrawFundsWireModal,
	ProfileModals,
	UserAccountsModals,
	CreateSignatureModal,
	WelcomePageModal,
	WithdrawFundsAchModal,
	WithdrawFundsBrokerModal,
	NewAgentWaitingModal,
	DemoAccountModal,
	AgentRegistrationFormModal,
	CommonWithdrawModuleModal,
} from './components';

const styles = theme => ({

});

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
	actions: {
		depositFundsAchClose: () => dispatch(depositFunds.actions.hideDepositFundsModal()),
		depositFundsBrokerClose: () => dispatch(depositFunds.actions.hideDepositFundsBrokerModal()),
		depositFundsWireClose: () => dispatch(depositFunds.actions.hideDepositFundsWireModal()),
		hideWithdrawFundsAchModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsAchModal()),
		hideWithdrawFundsWireModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsWireModal()),
		hideWithdrawFundsBrokerModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsBrokerModal()),
		hideConfirmModal: () => dispatch(app.actions.hideConfirmModal()),
		hideAgentRegistrationFormModal: () => dispatch(app.actions.hideAgentRegistrationFormModal()),
		hideMessageModal: () => dispatch(notifications.actions.hideMessageModal()),
		hideCreateSignatureModal: () => dispatch(signature.actions.hideCreateSignatureModal()),
		hideNewAgentWaitingModal: () => dispatch(app.actions.hideNewAgentWaitingModal()),
	},
});

class Modals extends React.Component {
	getModal = item => {
		const {
			actions,
		} = this.props;

		// !!! Modals must be in current order !!!

		switch (item) {
			case ModalPopups.WELCOME_PAGE:
				return (
					<WelcomePageModal
						key={`${ModalPopups.WELCOME_PAGE}Modal`}
						onClose={() => this.closeModal(ModalPopups.WELCOME_PAGE)}
					/>
				);
			case ModalPopups.ADD_BANK_ACCOUNT:
				return (
					<AddBankAccountModal
						key={`${ModalPopups.ADD_BANK_ACCOUNT}Modal`}
						onClose={() => this.closeModals([ModalPopups.ADD_BANK_ACCOUNT, ModalPopups.PLAID_SELECT_ACCOUNT])}
					/>
				);
			case ModalPopups.CREATE_APIKEY:
				return (
					<CreateApiKeyModal
						key={`${ModalPopups.CREATE_APIKEY}Modal`}
						onClose={() => this.closeModal(ModalPopups.CREATE_APIKEY)}
					/>
				);
			case ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS:
				return (
					<CYTransferBetweenAccountsModal
						key={`${ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER:
				return (
					<CYWithdrawalBankTransferModal
						key={`${ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET:
				return (
					<CYWithdrawalCryptoWalletModal
						key={`${ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK:
				return (
					<CYWithdrawalMalaysianBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_KOREAN_BANK:
				return (
					<CYWithdrawalKoreanBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_KOREAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_KOREAN_BANK)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_KENYAN_BANK:
				return (
					<CYWithdrawalKenyanBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_KENYAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_KENYAN_BANK)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_NIGERIAN_BANK:
				return (
					<CYWithdrawalNigerianBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_NIGERIAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_NIGERIAN_BANK)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_INDONESIAN_BANK:
				return (
					<CYWithdrawalIndonesianBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_INDONESIAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_INDONESIAN_BANK)}
					/>
				);
			case ModalPopups.CY_WITHDRAWAL_INDIAN_BANK:
				return (
					<CYWithdrawalIndianBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_INDIAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_INDIAN_BANK)}
					/>
				);
			case ModalPopups.DEPOSIT_FUNDS_ACH:
				return (
					<DepositFundsAchModal
						key={`${ModalPopups.DEPOSIT_FUNDS_ACH}Modal`}
						onClose={actions.depositFundsAchClose}
					/>
				);
			case ModalPopups.DEPOSIT_FUNDS_BROKER:
				return (
					<DepositFundsBrokerModal
						key={`${ModalPopups.DEPOSIT_FUNDS_BROKER}Modal`}
						onClose={actions.depositFundsBrokerClose}
					/>
				);
			case ModalPopups.DEPOSIT_FUNDS_WIRE:
				return (
					<DepositFundsWireModal
						key={`${ModalPopups.DEPOSIT_FUNDS_WIRE}Modal`}
						onClose={actions.depositFundsWireClose}
					/>
				);
			case ModalPopups.PLAID_SELECT_ACCOUNT:
				return (
					<PlaidSelectAccountModal
						key={`${ModalPopups.PLAID_SELECT_ACCOUNT}Modal`}
						onClose={() => this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT)}
					/>
				);
			case ModalPopups.WITHDRAW_FUNDS_ACH:
				return (
					<WithdrawFundsAchModal
						key={`${ModalPopups.WITHDRAW_FUNDS_ACH}Modal`}
						onClose={actions.hideWithdrawFundsAchModal}
					/>
				);
			case ModalPopups.WITHDRAW_FUNDS_WIRE:
				return (
					<WithdrawFundsWireModal
						key={`${ModalPopups.WITHDRAW_FUNDS_WIRE}Modal`}
						onClose={() => this.closeModal(ModalPopups.WITHDRAW_FUNDS_WIRE)}
					/>
				);
			case ModalPopups.WITHDRAW_FUNDS_BROKER:
				return (
					<WithdrawFundsBrokerModal
						key={`${ModalPopups.WITHDRAW_FUNDS_BROKER}Modal`}
						onClose={actions.hideWithdrawFundsBrokerModal}
					/>
				);
			case ModalPopups.COMMISSION_FREE_MODAL:
				return (
					<CommissionFreeModal
						key={`${ModalPopups.COMMISSION_FREE_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.COMMISSION_FREE_MODAL)}
					/>
				);
			case ModalPopups.CONFIRM_MODAL:
				return (
					<ConfirmModal
						key={`${ModalPopups.CONFIRM_MODAL}Modal`}
						onClose={actions.hideConfirmModal}
					/>
				);
			case ModalPopups.J2T_LITE:
				return (
					<J2tLiteModal
						key={`${ModalPopups.J2T_LITE}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2T_LITE)}
					/>
				);
			case ModalPopups.J2T_PLUS:
				return (
					<J2tPlusModal
						key={`${ModalPopups.J2T_PLUS}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2T_PLUS)}
					/>
				);
			case ModalPopups.J2T_PRO:
				return (
					<J2tProModal
						key={`${ModalPopups.J2T_PRO}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2T_PRO)}
					/>
				);
			case ModalPopups.MT5:
				return (
					<Mt5Modal
						key={`${ModalPopups.MT5}Modal`}
						onClose={() => this.closeModal(ModalPopups.MT5)}
					/>
				);
			case ModalPopups.ST_PRO:
				return (
					<StProModal
						key={`${ModalPopups.ST_PRO}Modal`}
						onClose={() => this.closeModal(ModalPopups.ST_PRO)}
					/>
				);
			case ModalPopups.MESSAGE:
				return (
					<MessageModal
						key={`${ModalPopups.MESSAGE}Modal`}
						onClose={actions.hideMessageModal}
					/>
				);
			case ModalPopups.CREATE_SIGNATURE:
				return (
					<CreateSignatureModal
						key={`${ModalPopups.CREATE_SIGNATURE}Modal`}
						onClose={actions.hideCreateSignatureModal}
					/>
				);
			case ModalPopups.DEMO_ACCOUNT_EXPIRATION:
				return (
					<DemoAccountModal
						key={`${ModalPopups.DEMO_ACCOUNT_EXPIRATION}Modal`}
						onClose={false}
					/>);
			case ModalPopups.AGENT_REGISTRATION_MODAL:
				return (
					<AgentRegistrationFormModal
						key={`${ModalPopups.AGENT_REGISTRATION_MODAL}Modal`}
						onClose={actions.hideAgentRegistrationFormModal}
					/>);
			case ModalPopups.NEW_AGENT_WAITING_MODAL:
				return (
					<NewAgentWaitingModal
						key={`${ModalPopups.NEW_AGENT_WAITING_MODAL}Modal`}
						onClose={actions.hideNewAgentWaitingModal}
						/>);
			case ModalPopups.COMMON_WITHDRAW_MODULE_MODAL:
				return (
					<CommonWithdrawModuleModal
						key={`${ModalPopups.COMMON_WITHDRAW_MODULE_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.COMMON_WITHDRAW_MODULE_MODAL)}
					/>);
			default:
				return null;
		}
	}

	closeModal = id => {
		this.props.history.push(removeHashFromUrl(this.props.location, id));
	}

	closeModals = ids => {
		this.props.history.push(removeHashesFromUrl(this.props.location, ids));
	}

	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	render() {
		const {
			location,
		} = this.props;

		const modals = location.hash && location.hash.substr(1).split('/');
		const topModal = modals && modals.length > 0 && this.getModal(modals && modals.pop());
		return (
			<div>
				{topModal}
				{/* allways show ProfileModals, because it has email confirmation functionality */}
				<ProfileModals />
				<UserAccountsModals />
				<CYNonTradingOrderModals />
			</div>
		);
	}
}

Modals.propTypes = {
	actions: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Modals);
