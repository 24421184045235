import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	Button,
	ProgressContainer,
} from '@components';
import { FormInput, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		paddingTop: '12px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.background.paper,
		},
	}
});


const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	return {
		initialValues: {
			Value: userProfile.PersonalInformation.Dependents,
		},
		profileSubmitting: userProfile.submitting,
		pendingOrder: userProfile.PendingOrders.Dependents,
	};
};

class ChangeDependentsModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Value) {
			errors.Value = 'required';
		}
		return errors;
	}

	checkDependents = (value) => {
		if (value < 1) {
			return 1;
		}
		return value;
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			pristine,
			profileSubmitting,
			pendingOrder,
			submitting,
			submitSucceeded,
		} = this.props;

		return (
			<RequireSignatureModal
				title="Change Dependents"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_DEPENDENTS}`}
			>
				{submitSucceeded && <SuccessMessage onClick={onClose} text="Your request to change dependents is processed." />}
				{!submitSucceeded && !profileSubmitting && pendingOrder &&
					<ErrorMessage onClick={onClose} text="Another request for dependents change is in progress. Please try again later." />
				}
				{!submitSucceeded && !pendingOrder &&
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileDependentsFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									type="number"
									normalize={this.checkDependents}
									name="Value"
									autoComplete="off"
									label="Please enter your new dependents"
									additionalText="Number of dependents, including yourself."
								/>
							</div>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting || pristine}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Change Dependents'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeDependentsModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'ChangeProfileDependentsForm',
		validate: ChangeDependentsModal.validate,
		enableReinitialize: true,
	}),
)(ChangeDependentsModal);
