import { all } from 'redux-saga/effects';
import {
	app,
	agent,
	userAccounts,
	dashboard,
	linkedAccounts,
	loginForm,
	depositFunds,
	apiKeys,
	nontradingOrders,
	ordersCy,
	notifications,
	withdrawFunds,
	auth,
	tradePlatforms,
	profile,
	reset,
	signalR,
	signature,
	wallet,
	rates,
	captcha,
	twoFactor,
	alerts,
	withdrawWidget,
} from './';

import reportSaga from './reports/saga'

export default function* sagas() {
	yield all([
		...dashboard.saga.rootSaga,
		...app.saga.rootSaga,
		...auth.saga.rootSaga,
		...agent.saga.rootSaga,
		...userAccounts.saga.rootSaga,
		...linkedAccounts.saga.rootSaga,
		...loginForm.saga.rootSaga,
		...depositFunds.saga.rootSaga,
		...apiKeys.saga.rootSaga,
		...nontradingOrders.saga.rootSaga,
		...ordersCy.saga.rootSaga,
		...notifications.saga.rootSaga,
		...withdrawFunds.saga.rootSaga,
		...tradePlatforms.saga.rootSaga,
		...profile.saga.rootSaga,
		...reportSaga.rootSaga,
		...reset.saga.rootSaga,
		...signalR.saga.rootSaga,
		...signature.saga.rootSaga,
		...wallet.saga.rootSaga,
		...rates.saga.rootSaga,
		...captcha.saga.rootSaga,
		...twoFactor.saga.rootSaga,
		...withdrawWidget.saga.rootSaga,
		...alerts.saga.rootSaga,
	]);
}
