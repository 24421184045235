import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { app, profile, userAccounts } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
} from '@components';
import { R, useTranslate } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { InputEditableItem } from '@shared';

const useActions = () => {
	const dispatch = useDispatch();

	return ({
		showChangePasswordModal: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_CHANGE_PASSWORD)),
		showChangePhoneModal: () => dispatch(profile.actions.showChangePhoneModal()),
	});
};

const CYAccountCredentialsPanel = props => {
	const {
		onToggle,
		panelId,
		isOpen,
		classes,
	} = props;
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const actions = useActions();
	const translate = useTranslate();
	const { Guid, DemoUserId } = useSelector(userAccounts.selectors.userInfo);
	const isDemo = !Guid && !!DemoUserId;
	return (
		<ExpansionPanel
			header={<R id="common_header_account_credentials" defaultValue="Login Credentials" />}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
		>
			<InputEditableItem
				title={translate('common_password', 'Password')}
				value="*************"
				editControl={
					<ChangeLink onClick={actions.showChangePasswordModal} />
				}
			/>
			<InputEditableItem
				title={translate('common_phone', 'Phone')}
				value={userInfo.Phone}
				editControl={
					!isDemo && <ChangeLink onClick={actions.showChangePhoneModal} />
				}
			/>
		</ExpansionPanel>
	);
};

CYAccountCredentialsPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

CYAccountCredentialsPanel.defaultProps = {
	isOpen: false,
};

export default CYAccountCredentialsPanel;
