import React from 'react';
import compose from 'recompose/compose';
import { Button } from '@components';
import { R, FormPasswordField, withTranslate } from '@dev2t/react-ui-kit/src';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	requirement: {
		color: theme.palette.text.secondary,
		padding: '0 12px',
		'&:last-of-type': {
			marginBottom: '30px',
		},
	},
}));

const configuration = {
	minLength: 8,
	maxLength: 15,
    minLatinLowercaseSymbols: 1,
    minLatinUppercaseSymbols: 1,
    minDigits: 1,
	// при добавлении спецсимволов, особенно "-", в список убедиться что регулярное выражение не сломается
	allowedSymbols: ['!', '#', '$', '@', '?'],
};

const allowedSymbolsRegExp = new RegExp(`^[a-zA-Z0-9${configuration.allowedSymbols.join('')}]+$`);

const CY_CHANGE_MT_PASSWORD_FORM = 'CY_CHANGE_MT_PASSWORD_FORM';

const CYChangeMtPasswordForm = ({ handleSubmit, onSubmit, invalid, submitting, translate }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
            <Field
                component={FormPasswordField}
                name="NewPassword"
                label={translate('common_password', 'Password')}
                // предотвращает предложение браузера сохранить этот пароль как пароль от ЛК
                inputProps={{ autoComplete: 'one-time-code' }}
                eyeIconTitle={translate('shared_show_password', 'Show password')}
                disableUnderline
            />
            <Field
                component={FormPasswordField}
                name="RepeatPassword"
                label={translate('common_repeat_password', 'Repeat password')}
                // предотвращает предложение браузера сохранить этот пароль как пароль от ЛК
                inputProps={{ autoComplete: 'one-time-code' }}
                eyeIconTitle={translate('shared_show_password', 'Show password')}
                disableUnderline
            />
            <Box className={classes.requirement}>
                <R
                    id="change_password_latin"
                    defaultValue="Password must contain Latin characters"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_lower"
                    defaultValue="Password must contain lowercase characters"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_upper"
                    defaultValue="Password must contain uppercase characters"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="change_password_digits"
                    defaultValue="Password must contain digits"
                />
            </Box>
            <Box className={classes.requirement}>
                <R
                    id="allowed_special_symbols"
                    defaultValue={`Allowed special symbols: ${configuration.allowedSymbols}`}
                    translateParameters={{ symbols: configuration.allowedSymbols.join(' ') }}
                />
            </Box>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={invalid || submitting}
            >
                {submitting ? (
                    <CircularProgress
                        style={{ color: theme.palette.common.white }}
                        size={18}
                    />
                ) : (
                    <R id="common_change_password" defaultValue="Change password" />
                )}
            </Button>
        </form>
	);
};

export default compose(
	withTranslate,
	reduxForm({
		form: CY_CHANGE_MT_PASSWORD_FORM,
		initialValues: {
			NewPassword: '',
			RepeatPassword: '',
		},
		validate: (values, props) => {
			const errors = {};
			if (values.NewPassword && values.RepeatPassword &&
                values.NewPassword !== values.RepeatPassword
            ) {
				errors.RepeatPassword = props.translate('passwords_do_not_match', 'Password and confirmation do not match');
			}
			Object.entries(values).forEach(([key, value]) => {
				if (!value) {
					errors[key] = 'required';
					return errors;
				}
				if (value.length < configuration.minLength) {
					errors[key] = props.translate({
                        id: 'change_password_min_length',
                        defaultValue: `Minimum length is ${configuration.minLength} symbols`,
                        translateParameters: { minLength: configuration.minLength }
                    });
					return errors;
				}
				if (value.length > configuration.maxLength) {
                    errors[key] = props.translate({
                        id: 'change_password_max_length',
                        defaultValue: `Maximum length is ${configuration.maxLength} symbols`,
                        translateParameters: { maxLength: configuration.maxLength }
                    });
					return errors;
				}
				if (!value.match(/[a-z]/gu)) {
                    errors[key] = props.translate({
                        id: 'account_change_password_min_lowercase_latin_symbol',
                        defaultValue: `Password must containt at least ${configuration.minLatinLowercaseSymbols} lowercase latin symbol`,
                        translateParameters: { minLatinLowercaseSymbols: configuration.minLatinLowercaseSymbols }
                    });
					return errors;
				}
				if (!value.match(/[A-Z]/gu)) {
                    errors[key] = props.translate({
                        id: 'account_change_password_min_uppercase_latin_symbol',
                        defaultValue: `Password must containt at least ${configuration.minLatinUppercaseSymbols} uppercase latin symbol`,
                        translateParameters: { minLatinUppercaseSymbols: configuration.minLatinUppercaseSymbols }
                    });
					return errors;
				}
				if (!value.match(/[0-9]/gu)) {
                    errors[key] = props.translate({
                        id: 'account_change_password_min_digits',
                        defaultValue: `Password must containt at least ${configuration.minDigits} digit`,
                        translateParameters: { minDigits: configuration.minDigits }
                    });
					errors[key] = `Password must containt at least ${configuration.minDigits} digit`;
					return errors;
				}
				if (!value.match(allowedSymbolsRegExp)) {
					errors[key] = props.translate('password_contains_not_allowed_characters', 'Password contains not allowed characters');
					return errors;
				}
			});

			return errors;
		},
	}),
)(CYChangeMtPasswordForm);
