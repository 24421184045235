import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	withdrawFunds,
} from '@redux';
import {
	reduxForm, formValueSelector,
	Field,
} from 'redux-form';
import {
	FormSelect,
	Button,
	Divider,
	LimeLogo,
	SvgIcon,
} from '@components';
import { FormInputNumber, SuccessMessage } from '@dev2t/react-ui-kit/src';
import { constants } from '@config';
import { toUsaMoneyFormat } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import LongArrow from './arrow.svg';
import j2tlogo from './j2t.png';
import { WithdrawInfo, BrokerAccountInfo } from './components';

const styles = theme => ({
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	row: {
		display: 'flex',
	},
	amount: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	amountInput: {
		paddingLeft: '70px',
	},
	bankAccountContainer: {
		// marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	sourceAccountInfo: {
		marginTop: '0',
		marginBottom: '-48px',
		paddingLeft: '50px',
	},
	destinationAccountInfo: {
		marginTop: '0',
		paddingLeft: '50px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '84px',
	},
	arrowImg: {
		position: 'relative',
		marginTop: '-18px',
		top: '50%',

	},
	bankAccountLogo: {
		position: 'relative',
		width: '42px',
		height: '42px',
		top: '50%',
		marginTop: '-21px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	divider: {
		marginTop: '30px',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		marginTop: '45px',
	},
});

const WITHDRAW_FUNDS_BROKER_FORM = 'withdrawFundsBrokerForm';
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	return {
		initialValues: {
			bankAccount: ownProps.toBankAccount,
			bankAccountNumber: ownProps.toBankAccount.Number,
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
			Amount: ownProps.amount,
			Direction: 'IncToOnline',
			bankAccountInfo: {
				submitting: false,
				cashToWithdrawValue: null,
				totalAccountValue: null,
			},
		},
		getFormValue: fieldName => formValueSelector(WITHDRAW_FUNDS_BROKER_FORM)(state, fieldName),
		linkedAccounts: userAccounts.selectors.linkedAccounts(state),
		currentTradeAccount,
		currentAccountData: userAccounts.selectors.currentAccountData(state),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		hideWithdrawFundsBrokerModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsBrokerModal()),
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});

class BrokerWithdrawalForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(values, props) {
		const errors = {};
		const cashToWithdraw = BrokerWithdrawalForm.getCashToWithdraw(props);

		if (!values.bankAccount) {
			errors.bankAccount = 'required';
		}
		if (!values.Amount || values.Amount <= 0) {
			errors.Amount = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Amount) {
				errors.Amount = 'Available amount for withdrawal is less than requested';
			}
		}
		return errors;
	}

	render() {
		const {
			classes,
			actions,
			amount,
			getFormValue,
			linkedAccounts,
			handleSubmit,
			toBankAccount,
			submitSucceeded,
			currentTradeAccount,
			currentAccountData: {
				submitting,
				baseParams,
			},
			form,
			...props
		} = this.props;

		const selectedBankAccount = toBankAccount;

		return submitSucceeded ? <SuccessMessage
			onClick={actions.hideWithdrawFundsBrokerModal}
			text="Banking transaction completed"
			buttonText="Thank you"
		/> :
			(
				<form onSubmit={handleSubmit(withdrawFunds.actions.withdrawFundsBrokerForm)}>
					<div className={classes.accountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								<LimeLogo />
							</div>
							<Field component={FormSelect} name="TradeCode" label="Account" disabled>
								<MenuItem value={currentTradeAccount.tradeCode}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
									</div>
								</MenuItem>
							</Field>
						</div>
						<WithdrawInfo classes={{ root: classes.sourceAccountInfo }} tradeCode={getFormValue('TradeCode')} />
					</div>
					<div className={classes.arrow}>
						{selectedBankAccount &&
							<img alt="Long Arrow Down" src={LongArrow} className={classes.arrowImg} />
						}
					</div>
					<div className={classes.bankAccountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								{selectedBankAccount &&
									<img src={j2tlogo} alt="Just2Trade logo" className={classes.bankAccountLogo} />
								}
							</div>
							<Field component={FormSelect} name="bankAccountNumber" label="J2T Account" disabled>
								{
									[{
										Type: 'BROKER',
										Number: toBankAccount.Number,
										displayNumber: toBankAccount.Number,
										Balance: undefined,
									 }].map((bankAccount, index) => (
										<MenuItem value={bankAccount.Number} key={index}>
											<div className={classes.inputContainer}>
												<div className={classes.account}>*{bankAccount.displayNumber}</div>
												{bankAccount.Balance &&
													<div className={classes.accountBalance}>
														{toUsaMoneyFormat(bankAccount.Balance)}
													</div>
												}
											</div>
										</MenuItem>
									))
								}
							</Field>
						</div>
						{getFormValue('bankAccountNumber') &&
							<BrokerAccountInfo
								classes={{ root: classes.destinationAccountInfo }}
								account={getFormValue('bankAccountNumber')}
								accountInfo={getFormValue('bankAccountInfo')}
								name="bankAccountInfo"
								form={form}
							/>
						}
					</div>
					<div className={classes.inputItem}>
						<Field
							component={FormInputNumber}
							name="Amount"
							placeholder="Enter the Amount here"
							label="Amount"
							disabled={amount != null}
						/>
					</div>
					<div className={classes.divider}>
						<Divider />
					</div>
					<p className={classes.warning}>
						{constants.warningForNonTransferableSecuritiesHolders}
					</p>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={props.invalid || props.submitting}
						>
							{props.submitting ?
								<CircularProgress
									
									size={18}
								/>
								: 'Withdraw'
							}
						</Button>
					</div>
				</form>
			);
	}
}

BrokerWithdrawalForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
	fromUserAccount: PropTypes.object.isRequired, // not used
	toBankAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: WITHDRAW_FUNDS_BROKER_FORM,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess('BROKER');
			}
		},
		validate: BrokerWithdrawalForm.validate,
	}),
)(BrokerWithdrawalForm);
