/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import compose from 'recompose/compose';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { useInterval, useBalance } from '@hooks';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
	WithdrawAmount,
	FormSelect,
	LogoIcon,
	Button,
} from '@components';
import { R, FormInputNumber, useTranslate } from '@dev2t/react-ui-kit/src/components';
import { CircularProgress, Grid, MenuItem, useTheme, makeStyles } from '@material-ui/core';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import AdditionalInfo from './AdditionalInfo';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	paramRow: {
		display: 'flex',
		columnGap: theme.spacing(4),
		margin: theme.spacing(3, 0),
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
	},
	paramAmount: {
		flex: '1 1 auto',
	},
	paramCurrency: {
		flex: '0 0 100px',
	},
}));

const currencies = ['USD', 'EUR', 'BTC', 'ETH', 'XRP', 'USDT', 'LTC'];

function getCurrencyPrefix(currency) {
	switch (currency) {
		case 'USD':
			return '$';
		case 'EUR':
			return '€';
		case 'BTC':
			return '₿';
		case 'ETH':
			return 'Ξ';
		case 'XRP':
			return '✕';
		case 'USDT':
			return '₮';
		case 'LTC':
			return 'Ł';
		default:
			throw new Error(`bad currency:${currency}`);
	}
}

function getCurrencyMaxExchange(currency) {
	switch (currency) {
		case 'USD':
			return 1000000;
		case 'EUR':
			return 1000000;
		case 'BTC':
			return 30;
		case 'ETH':
			return 500;
		case 'XRP':
			return 2000000;
		case 'USDT':
			return 1000000;
		case 'LTC':
			return 10000;
		default:
			throw new Error(`bad currency:${currency}`);
	}
}

function getCurrencyMinExchange(currency) {
	switch (currency) {
		case 'USD':
			return 10;
		case 'EUR':
			return 10;
		case 'BTC':
			return 0.001;
		case 'ETH':
			return 0.005;
		case 'XRP':
			return 20;
		case 'USDT':
			return 10;
		case 'LTC':
			return 0.1;
		default:
			throw new Error(`bad currency:${currency}`);
	}
}

const lessThanBalance = (value, allValues, props) => {
	const { accountsInfo } = props;
	const { currencyFrom } = allValues;
	const balance = accountsInfo.find(x => x.TradeCode === allValues.tradeCode).Balance;
	const availableBalance = balance.CryptoCurrencies.find(x => x.CurrencyCode === currencyFrom) || balance.Currencies.find(x => x.CurrencyCode === currencyFrom);
	return availableBalance && availableBalance.Balance && value <= availableBalance.Balance
		? undefined
		: 'Not enough money';
};
const differentCurrencies = (value, allValues) => (allValues.currencyFrom === allValues.currencyTo ? 'Change currency' : undefined);
const positive = value => (value >= 0 ? undefined : 'isNegative');
const lessThanMax = (value, allValues) => (value <= getCurrencyMaxExchange(allValues.currencyFrom) ? undefined : 'More than allowed');
const moreThanMin = (value, allValues) => (value >= getCurrencyMinExchange(allValues.currencyFrom) ? undefined : 'Less than allowed');

const J2txCurrencyChangeForm = (props) => {
	const theme = useTheme();
	const classes = useStyles();
	const translate = useTranslate();

	const actualValues = useSelector(getFormValues(ModalPopups.J2TX_CHANGE_CURRENCY)) || {};
	const {
		Rate, loading, success,
	} = useSelector(userAccounts.selectors.rates) || {};

	const balance = useBalance(actualValues.tradeCode);
	
	const balanceForCurrency = balance
		?.find(x => x.CurrencyCode === actualValues.currencyFrom)
		?.Balance;

	const loadingAccounts = useSelector(userAccounts.selectors.accountsInfoLoading);
	const timeout = 30;

	const [isRateUpdateRequires, setIsRateUpdateRequires] = useState(false);
	useInterval(() => setIsRateUpdateRequires(true), timeout * 1000);

	const [timer, setTimer] = useState(timeout);
	useInterval(() => setTimer(timer - 1), 1000);

	const dispatch = useDispatch();
	const required = useRef(value => (value ? undefined : translate('shared_required', 'Required')));
	useEffect(() => {
		if (actualValues.currencyFrom !== actualValues.currencyTo) {
			dispatch(userAccounts.actions.getExchangeRate.request({ currencyFrom: actualValues.currencyFrom, currencyTo: actualValues.currencyTo }));
			setIsRateUpdateRequires(false);
		}
	}, [actualValues.currencyFrom, actualValues.currencyTo]);

	useEffect(() => {
		if (isRateUpdateRequires) {
			dispatch(userAccounts.actions.getExchangeRate.request({ currencyFrom: actualValues.currencyFrom, currencyTo: actualValues.currencyTo }));
			setIsRateUpdateRequires(false);
			setTimer(timeout);
		}
	}, [isRateUpdateRequires]);

	useEffect(() => {
		const amountTo = !loading && success && actualValues.amountFrom && actualValues.amountFrom > 0
			? Rate * actualValues.amountFrom
			: 0;
		dispatch(change(ModalPopups.J2TX_CHANGE_CURRENCY, 'amountTo', amountTo));
	}, [Rate, actualValues.amountFrom, loading]);

	const refreshBalance = () => dispatch(userAccounts.actions.getAllAccountsInfo.request());

	return (
		<form onSubmit={props.handleSubmit}>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item xs={4}>
					<Field
						component={FormSelect}
						name="currencyFrom"
						label={translate('currency_from', 'Currency from')}
						validate={[required.current, differentCurrencies]}
					>
						{currencies.map((x, index) => (
							<MenuItem key={index} value={x}>
								<div style={{ padding: '10px' }}>
									<LogoIcon hideLabel currency={x} />
								</div>
							</MenuItem>
						))}
					</Field>
				</Grid>
				<Grid item xs={8}>
					<WithdrawAmount
						name="amountFrom"
						label={translate('amount_from', 'Amount from')}
						loading={loadingAccounts}
						availableAmount={balanceForCurrency}                        
						withdrawLabelText={translate('current_balance', 'Current balance')}
						validate={[positive, required.current, lessThanBalance, lessThanMax, moreThanMin]}
						allowIconButtons={false}
						prefix={getCurrencyPrefix(actualValues.currencyFrom)}
						currency={actualValues.currencyFrom}
						onClick={refreshBalance}
					/>
				</Grid>
				<Grid item xs={4}>
					<Field
						component={FormSelect}
						name="currencyTo"
						label={translate('currency_to', 'Currency to')}
						validate={[required.current, differentCurrencies]}
					>
						{currencies.filter(x => x !== actualValues.currencyFrom).map((x, index) => (
							<MenuItem key={index} value={x}>
								<div style={{ padding: '10px' }}>
									<LogoIcon hideLabel currency={x} />
								</div>
							</MenuItem>
						))}
					</Field>
				</Grid>
				<Grid item xs={8}>
					<Field
						component={FormInputNumber}
						name="amountTo"
						label={translate('amount_to', 'Amount to')}
						inputProps={{ prefix: getCurrencyPrefix(actualValues.currencyTo) }}
						allowIconButtons={false}
						validate={[required.current]}
						disabled
					/>
				</Grid>
			</Grid>

			<AdditionalInfo
				loading={loading}
				success={success}
				rate={Rate}
				currencyFrom={actualValues.currencyFrom}
				currencyTo={actualValues.currencyTo}
				timer={timer}
				max={getCurrencyMaxExchange(actualValues.currencyFrom)}
				min={getCurrencyMinExchange(actualValues.currencyFrom)}
			/>

			<Button
				fullWidth
				type="submit"
				variant="contained"
				color="primary"
				disabled={props.invalid || props.submitting || loading || !success || loadingAccounts}
			>
				{props.submitting
					? <CircularProgress
						style={{ color: theme.palette.common.white }}
						size={18}
					/>
					: <R id="common_btn_continue" defaultValue="Continue" />
				}
			</Button>
		</form>
	);
};

export default compose(
	connect(state => ({
		initialValues: userAccounts.selectors.modals(state)[ModalPopups.J2TX_CHANGE_CURRENCY],
		accountsInfo: userAccounts.selectors.accountsInfo(state),
	})),
	reduxForm({
		initialValues: {
			amountTo: 0,
			amountFrom: 0,
		},
		form: ModalPopups.J2TX_CHANGE_CURRENCY,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			const errorMessage = errors[''] || errors._error;
			if (errorMessage && props.onFail) {
				props.onFail(errorMessage);
			}
		},
	}),
)(J2txCurrencyChangeForm);
