import React from 'react';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { dashboard, notifications } from '@redux';
import { NotificationModalContent, Modal } from '@dev2t/react-ui-kit/src';

class MessageModal extends React.Component {
	closeModal = (event) => {
		if (this.props.onClose) {
			this.props.onClose(event);
		}
	}

	render() {
		return (
			<Modal
				title=""
				onClose={this.closeModal}
				className={`QA-${ModalPopups.MESSAGE}`}
			>
				<NotificationModalContent />
			</Modal>
		);
	}
}

MessageModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default MessageModal;
