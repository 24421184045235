import { put, select, fork, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { version, changeLanguage as changeLanguageApiRequest } from '@api';
import { env } from '@config';
import { ModalPopups } from '@enums';
import { addHashToUrl, cabinetLog, removeHashFromUrl } from '@global';
import { callApi } from '@redux';
import { Cookies } from 'react-cookie';
import actions from './actions';
import depositFunds from '../depositFunds';
import notifications from '../notifications';
import userAccounts from '../userAccounts';
import alerts from '../alerts';
import { BaseGetSagaHandler } from '../sagaExtensions';

function* changeLanguage({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: changeLanguageApiRequest,
		errorText: 'Change language request',
		handler: actions.changeLanguage,
	}, { payload });
}

function* changeLanguageSuccess() {
	const currentAccount = yield select(userAccounts.selectors.currentAccount);
	if (currentAccount) {
		yield put(depositFunds.actions.clearAccountFundingInfo());
		yield put(notifications.actions.reloadNotifications.callHandler());
		yield put(alerts.actions.getAlerts.request());
	}
}

function* showConfirmModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.CONFIRM_MODAL)));
}

function* hideConfirmModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.CONFIRM_MODAL)));
}

function* showNewAgentWaitingModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.NEW_AGENT_WAITING_MODAL)));
}

function* hideNewAgentWaitingModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.NEW_AGENT_WAITING_MODAL)));
}

function* showAgentRegistrationFormModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.AGENT_REGISTRATION_MODAL)));
}

function* hideAgentRegistrationFormModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.AGENT_REGISTRATION_MODAL)));
}

function* showBaseModal({ payload: { modalName } }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, modalName)));
}

function* hideBaseModal({ payload: { modalName } }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, modalName)));
}

function* checkMinVersionSaga() {
	try {
		const response = yield callApi(version);
		if (response.data.Success) {
			if (!env.version || env.version !== response.data.Version) {
				window.location.reload(true);
			}
		}
	} catch (error) {
		cabinetLog(error);
	}
}

function showDicWordsKeysSaga() {
	const exp = new Date();
	exp.setTime(exp.getTime() + 24 * 60 * 60 * 1000);
	const cookies = new Cookies();
	cookies.set('show_dicwords_keys', '1', {path: "/", expires: exp});
}

function hideDicWordsKeysSaga() {
	const cookies = new Cookies();
	cookies.remove('show_dicwords_keys', { path: '/' });
}

function* watchShowDicWordsKeysRequest() {
	yield takeEvery(actions.SHOW_DICWORDS, showDicWordsKeysSaga);
}

function* watchHideDicWordsKeysRequest() {
	yield takeEvery(actions.HIDE_DICWORDS, hideDicWordsKeysSaga);
}

function* watchChangeLanguage() {
	yield takeEvery(actions.changeLanguage.REQUEST, changeLanguage);
}

function* watchChangeLanguageSuccess() {
	yield takeEvery(actions.changeLanguage.SUCCESS, changeLanguageSuccess);
}

function* watchShowConfirmModal() {
	yield takeEvery(actions.SHOW_CONFIRM_MODAL, showConfirmModal);
}

function* watchHideConfirmModal() {
	yield takeEvery(actions.HIDE_CONFIRM_MODAL, hideConfirmModal);
}

function* watchShowNewAgentWaitingModal() {
	yield takeEvery(actions.SHOW_NEW_AGENT_WAITING_MODAL, showNewAgentWaitingModal);
}

function* watchHideNewAgentWaitingModal() {
	yield takeEvery(actions.HIDE_NEW_AGENT_WAITING_MODAL, hideNewAgentWaitingModal);
}
function* watchShowAgentRegistrationFormModal() {
	yield takeEvery(actions.SHOW_AGENT_REGISTRATION_MODAL, showAgentRegistrationFormModal);
}

function* watchHideAgentRegistrationFormModal() {
	yield takeEvery(actions.HIDE_AGENT_REGISTRATION_MODAL, hideAgentRegistrationFormModal);
}

function* watchShowBaseModal() {
	yield takeEvery(actions.SHOW_BASE_MODAL, showBaseModal);
}

function* watchHideBaseModal() {
	yield takeEvery(actions.HIDE_BASE_MODAL, hideBaseModal);
}

function* watchMinVersionRequest() {
	yield takeEvery(actions.CHECK_MIN_VERSION, checkMinVersionSaga);
}

// Root saga
const rootSaga = [
	fork(watchShowConfirmModal),
	fork(watchHideConfirmModal),
	fork(watchShowAgentRegistrationFormModal),
	fork(watchHideAgentRegistrationFormModal),
	fork(watchShowBaseModal),
	fork(watchHideBaseModal),
	fork(watchMinVersionRequest),
	fork(watchShowDicWordsKeysRequest),
	fork(watchHideDicWordsKeysRequest),
	fork(watchChangeLanguage),
	fork(watchChangeLanguageSuccess),
	fork(watchShowNewAgentWaitingModal),
	fork(watchHideNewAgentWaitingModal),
];

export default {
	rootSaga,
};
