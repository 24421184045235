import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Divider } from '@components';
import { R, FormPasswordField } from '@dev2t/react-ui-kit/src/components';
import { constants } from '@config';
import { translate } from '@global';
import { userAccounts } from '@redux';
import { RequireTotpConfirmationCodeItem } from '@shared';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	inputItem: {
		marginTop: '30px',
	},
	divider: {
		marginTop: '30px',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	сircularProgress: {
		color: 'inherit',
	},
});

const CY_CHANGE_PASSWORD_FORM_ID = 'CYChangePasswordForm';
const mapStateToProps = (state, ownProps) => ({
	isTotpEnabled: userAccounts.selectors.userInfo(state).IsTotpEnabled,
	initialValues: {
		AuthKey: ownProps.resetKey,
		OldPassword: '',
		NewPassword: '',
		ConfirmPassword: '',
	},

});

class CYChangePasswordForm extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (!values.OldPassword) {
			errors.OldPassword = translate('shared_required', 'Required');
		}

		if (!values.NewPassword) {
			errors.NewPassword = translate('shared_required', 'Required');
		} else if (!constants.regExpPassword.test(values.NewPassword)) {
			errors.NewPassword = 'Does not meet requirements';
		}
		if (!values.ConfirmPassword) {
			errors.ConfirmPassword = translate('shared_required', 'Required')
		} else if (values.NewPassword !== values.ConfirmPassword) {
			errors.ConfirmPassword = 'The new password and confirmation password do not match';
		}

		return errors;
	}

	render() {
		const {
			classes,
			handleSubmit,
			invalid,
			submitting,
			pristine,
			resetKey,
			isTotpEnabled,
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
				autoComplete="off"
			>
				{!resetKey
					&& (
						<div className={classes.inputItem}>
							<Field
								component={FormPasswordField}
								name="OldPassword"
								label={translate('common_changepassword_old_password', 'Old password')}
								eyeIconTitle={translate('shared_show_password', 'Show password')}
								disableUnderline
							/>
						</div>
					)}
				<div className={classes.inputItem}>
					<Field
						component={FormPasswordField}
						name="NewPassword"
						label={translate('common_changepassword_new_password', 'New password')}
						inputProps={{ autoComplete: 'new-password' }}
						eyeIconTitle={translate('shared_show_password', 'Show password')}
						disableUnderline
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormPasswordField}
						name="ConfirmPassword"
						label={translate('shared_confirm_password', 'Confirm Password')}
						inputProps={{ autoComplete: 'new-password' }}
						eyeIconTitle={translate('shared_show_password', 'Show password')}
						disableUnderline
					/>
				</div>
				{isTotpEnabled &&
					<div className={classes.inputItem}>
						<RequireTotpConfirmationCodeItem
							confirmationCodeFieldName="ConfirmationCode"
							disabled={submitting} />
					</div>}
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.warning}>
					<R id="common_password_least_charachters" defaultValue="Password must be at least 6 charachters long and contain each charachter type:" />
					<ul>
						<li>
							<R id="common_uppercase_letters" defaultValue="Uppercase letters: A-Z" />
						</li>
						<li>
							<R id="common_lowercase_letters" defaultValue="Lowercase letters: a-z" />
						</li>
						<li>
							<R id="common_numbers_valid_password" defaultValue="Numbers: 0-9" />
						</li>
					</ul>
					<R id="common_password_must_not_match" defaultValue="And password must not match with simple passwords (qwerty, 123456, etc.)" />
				</div>
				
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={invalid || pristine || submitting}
					>
						{submitting
							? (
								<CircularProgress
									className={classes.сircularProgress}
									size={18}
								/>
							)
							: <R id="comon_settings_password_copy" defaultValue="Change Password" />}
					</Button>
				</div>
			</form>
		);
	}
}

CYChangePasswordForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	resetKey: PropTypes.string,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_CHANGE_PASSWORD_FORM_ID,
		validate: CYChangePasswordForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(CYChangePasswordForm);
