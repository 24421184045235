import { createApiActions } from "../extensions"

const actions = {
	// actions

	SET_RESOLUTION: 'APP/SET_RESOLUTION',
	setResolution: resolution => ({
		type: actions.SET_RESOLUTION,
		payload: {
			resolution,
		},
	}),

	SHOW_CONFIRM_MODAL: 'APP/SHOW_CONFIRM_MODAL',
	HIDE_CONFIRM_MODAL: 'APP/HIDE_CONFIRM_MODAL',

	showConfirmModal: payload => ({
		type: actions.SHOW_CONFIRM_MODAL,
		payload,
	}),
	hideConfirmModal: () => ({
		type: actions.HIDE_CONFIRM_MODAL,
	}),

	SHOW_NEW_AGENT_WAITING_MODAL: 'APP/SHOW_NEW_AGENT_WAITING_MODAL',
	HIDE_NEW_AGENT_WAITING_MODAL: 'APP/HIDE_NEW_AGENT_WAITING_MODAL',

	showNewAgentWaitingModal: payload => ({
		type: actions.SHOW_NEW_AGENT_WAITING_MODAL,
		payload,
	}),
	hideNewAgentWaitingModal: () => ({
		type: actions.HIDE_NEW_AGENT_WAITING_MODAL,
	}),

	SHOW_AGENT_REGISTRATION_MODAL: 'APP/SHOW_AGENT_REGISTRATION_MODAL',
	HIDE_AGENT_REGISTRATION_MODAL: 'APP/HIDE_AGENT_REGISTRATION_MODAL',

	showAgentRegistrationFormModal: () => ({
		type: actions.SHOW_AGENT_REGISTRATION_MODAL,
	}),
	hideAgentRegistrationFormModal: () => ({
		type: actions.HIDE_AGENT_REGISTRATION_MODAL,
	}),

	SHOW_BASE_MODAL: 'APP/SHOW_BASE_MODAL',
	HIDE_BASE_MODAL: 'APP/HIDE_BASE_MODAL',

	showBaseModal: (modalName, modalData) => ({
		type: actions.SHOW_BASE_MODAL,
		payload: {
			modalName,
			modalData
		},
	}),
	hideBaseModal: (modalName) => ({
		type: actions.HIDE_BASE_MODAL,
		payload: {
			modalName,
		},
	}),

	CHECK_MIN_VERSION: 'APP/CHECK_MIN_VERSION',

	checkMinVersionRequest: () => ({
		type: actions.CHECK_MIN_VERSION,
	}),

	changeLanguage: createApiActions('APP/CHANGE_LANGUAGE'),

	SHOW_DICWORDS: 'APP/SHOW_DICWORDS',
	HIDE_DICWORDS: 'APP/HIDE_DICWORDS',

	showDicwords: () => ({
		type: actions.SHOW_DICWORDS,
	}),
	hideDicwords: () => ({
		type: actions.HIDE_DICWORDS,
	}),
};


export default actions;
