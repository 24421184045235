import { call, fork, takeEvery, put, select, delay } from 'redux-saga/effects';
import { auth, callApi, signalR, snackbar, userAccounts } from '@redux';
import { autologon, logout, pingauth, sendconfirmationcode, sendemailconfirmationcode, autologondemo } from '@api';
import { constants, env } from '@config';
import { cabinetLog, globalState, captureException, captureError } from '@global';
import { push } from 'connected-react-router';
import actions from './actions';

function* loginLocalSaga({ payload: { username, token, location } }) {
	try {
		globalState.localToken = token; // response.data.SignalRToken;
		window.Raven.setUserContext({
			login: username,
		});
		yield put(push(location ? `${location.pathname}${location.search}` : '/dashboard'));
		yield put(actions.sendPingAuthRequest());
	} catch (error) {
		captureError(error);
		console.log(error);
	}
}

function* loginSaga() {
	// used for clearing all prev data by reducers

	// and logout prev user if he was authorized
	try {
		const response = yield callApi(logout);
		cabinetLog('prev logout response', response);

		const afterAuthUrl = yield select(auth.selectors.afterAuthUrl);
		const location = yield select(state => state.router.location);
		const nextRoute = afterAuthUrl || location.pathname;
		cabinetLog('push on ', nextRoute);
		yield put(push(nextRoute));
	} catch (err) {
		if (err.message === 'Server error') {
			captureException('Authorization error', err);
			const authWidget = yield select(auth.selectors.authWidget);
			authWidget.logout();
			yield put(actions.logoutSuccess());
			yield put(snackbar.actions.showErrorMessage({
				title: 'Authorization error',
				text: 'Server error',
			}));
		} else {
			captureError(err);
		}
	}
}

function* logoutSaga() {
	try {
		if (!env.useLocalAuth) {
			const authWidget = yield select(auth.selectors.authWidget);
			if (!authWidget) {
				// no widget - client is unauthorized - do nothing
				cabinetLog('no widget');
				return;
			}
			authWidget.logout();

			if (!!env.signalRUrl) {
				yield put(signalR.actions.disconnectSignalR());
			}

			// always logout on server, otherwise prev user data will live in the server cache for a while.
			const response = yield callApi(logout);

		} else { // useLocalAuth

			if (!!env.signalRUrl) {
				yield put(signalR.actions.disconnectSignalR());
			}
			globalState.localToken = null;
			// always logout on server, otherwise prev user data will live in the server cache for a while.
			const response = yield callApi(logout);
			// важно сначала перейти на форму логина, а потом вызвать logoutSuccess (при котором чистятся стейты),
			// потому что иначе пустой стейт может привести к падению реакта
			yield put(push('/'));
			yield put(actions.logoutSuccess());
		}
	} catch (error) {
		captureError(error);
		cabinetLog(error);
	}
}

function* checkAuthRequestSaga() {
	try {
		const response = yield callApi(pingauth);

		if (response.data.Success || response.data.Errors[0].Code === 'Authorized')
		{
			yield put(actions.checkAuth.success());
			yield put(actions.loginLocal({ username: response.data.Login, token: response.data.SignalRToken }));
			return;
		}
		
		globalState.localToken = null;
		yield put (actions.checkAuth.failure());
		window.Raven.setUserContext();
		yield put(push('/login'));

	} catch (error) {
		captureError(error);
		cabinetLog(error);
		yield put (actions.checkAuth.failure());
		globalState.localToken = null;
		window.Raven.setUserContext();
		yield put(push('/login'));
	}
}

function* pingAuthRequestSaga() {
	yield delay(30 * 1000);
	if (!globalState.localToken) {
		return;
	}

	try {
		const response = yield callApi(pingauth);
		if (!response.data.Success) {
			if (response.data.Errors[0].Code !== 'Authorized') {
				// Никаких вызовов не требуется, т.к при обработке callApi итак вызывается разлогин
				return;
			}
		}
		yield put(actions.sendPingAuthRequest());
	} catch (error) {
		captureError(error);
		cabinetLog(error);
	}
}

function* autologinRequestSaga({ payload: { autologin } }) {
	try {
		const response = yield callApi(autologon, autologin);
		if (!response.data.Success) {
			if (response.data.Errors[0].Code === 'Authorized') {
				const userInfo = yield select(userAccounts.selectors.userInfo);
				yield put(actions.login(userInfo.Login));
				cabinetLog('success auth', response.data);
				yield put(auth.actions.loginLocal({ username: userInfo.Login, token: response.data.SignalRToken }));
			} else {
				globalState.localToken = null;
				window.Raven.setUserContext();
				yield put(push('/login'));
			}
		} else {
			cabinetLog('success auth', response.data);
			yield put(auth.actions.loginLocal({ username: response.data.Login, token: response.data.SignalRToken }));
		}
	} catch (error) {
		captureError(error);
		cabinetLog(error);
		globalState.localToken = null;
		window.Raven.setUserContext();
		yield put(push('/login'));
	}
}
function* autologinDemoRequestSaga({ payload: { authdemoid,	authtoken,	expired} }) {
	try {
		const response = yield callApi(autologondemo, authdemoid,	authtoken,	expired);
		if (!response.data.Success) {
			if (response.data.Errors[0].Code === 'Authorized') {
				const userInfo = yield select(userAccounts.selectors.userInfo);
				yield put(actions.login(userInfo.Login));
				cabinetLog('success auth', response.data);
				yield put(auth.actions.loginLocal({ username: userInfo.Login, token: response.data.SignalRToken }));
			} else {
				globalState.localToken = null;
				window.Raven.setUserContext();
				yield put(push('/login'));
			}
		} else {
			cabinetLog('success auth', response.data);
			yield put(auth.actions.loginLocal({ username: response.data.Login, token: response.data.SignalRToken }));
		}
	} catch (error) {
		captureError(error);
		cabinetLog(error);
		globalState.localToken = null;
		window.Raven.setUserContext();
		yield put(push('/login'));
	}
}


function* sendConfirmationCodeRequestSaga({ payload: { phone, reason } }) {
	try {
		const request = yield callApi(sendconfirmationcode, phone, reason);
		if (!request.data.Success) {
			yield put(actions.sendConfirmationCodeFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Send Confirmation Code', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.sendConfirmationCodeSuccess());
		}
	} catch (error) {
		captureError(error);
		yield put(actions.sendConfirmationCodeFailure(error));
	}
}

function* sendEmailConfirmationCodeRequestSaga({ payload: { email, reason } }) {
	try {
		const request = yield callApi(sendemailconfirmationcode, email, reason);
		if (!request.data.Success) {
			yield put(actions.sendEmailConfirmationCodeFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Send Confirmation Code', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.sendEmailConfirmationCodeSuccess());
		}
	} catch (error) {
		captureError(error);
		yield put(actions.sendEmailConfirmationCodeFailure(error));
	}
}

// Watchers
function* watchLoginLocal() {
	yield takeEvery(actions.LOGIN_LOCAL, loginLocalSaga);
}

function* watchLogin() {
	yield takeEvery(actions.LOGIN_USER, loginSaga);
}
function* watchLogout() {
	yield takeEvery(actions.LOGOUT, logoutSaga);
}
function* watchcheckAuthRequest() {
	yield takeEvery(actions.checkAuth.REQUEST, checkAuthRequestSaga);
}
function* watchAutologinRequest() {
	yield takeEvery(actions.AUTOLOGIN_REQUEST, autologinRequestSaga);
}
function* watchAutologinDemoRequest() {
	yield takeEvery(actions.AUTOLOGINDEMO_REQUEST, autologinDemoRequestSaga);
}
function* watchSendConfirmationCodeRequest() {
	yield takeEvery(actions.SEND_CONFIRMATION_CODE_REQUEST, sendConfirmationCodeRequestSaga);
}
function* watchEmailSendConfirmationCodeRequest() {
	yield takeEvery(actions.SEND_EMAIL_CONFIRMATION_CODE_REQUEST, sendEmailConfirmationCodeRequestSaga);
}
function* watchPingAuthRequest() {
	yield takeEvery(actions.SEND_PING_AUTH_REQUEST, pingAuthRequestSaga);
}

// Root saga
const rootSaga = [
	fork(watchLoginLocal),
	fork(watchLogin),
	fork(watchLogout),
	fork(watchcheckAuthRequest),
	fork(watchAutologinRequest),
	fork(watchAutologinDemoRequest),
	fork(watchSendConfirmationCodeRequest),
	fork(watchEmailSendConfirmationCodeRequest),
	fork(watchPingAuthRequest),
];

export default {
	rootSaga,
	pingAuthRequestSaga,
};
