import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import {
	userAccounts,
	dashboard,
	linkedAccounts,
    loginForm,
	agent,
	auth,
	depositFunds,
	apiKeys,
	nontradingOrders,
	ordersCy,
	notifications,
	tradePlatforms,
	withdrawFunds,
	signalR,
	snackbar,
	profile,
	reset,
	signature,
	app,
	wallet,
	rates,
	captcha,
	twoFactor,
	alerts,
} from './';

import reportReducer from './reports/reducer';

const createRootReducer = history => combineReducers({
	auth: auth.reducer,
	dashboard: dashboard.reducer,
	agent: agent.reducer,
	userAccounts: userAccounts.reducer,
	form: formReducer,
	linkedAccounts: linkedAccounts.reducer,
	loginForm: loginForm.reducer,
	nontradingOrders: nontradingOrders.reducer,
	ordersCy: ordersCy.reducer,
	notifications: notifications.reducer,
	depositFunds: depositFunds.reducer,
	apiKeys: apiKeys.reducer,
	withdrawFunds: withdrawFunds.reducer,
	tradePlatforms: tradePlatforms.reducer,
	signalR: signalR.reducer,
	snackbar: snackbar.reducer,
	profile: profile.reducer,
	reset: reset.reducer,
	reports: reportReducer,
	router: connectRouter(history),
	signature: signature.reducer,
	wallet: wallet.reducer,
	rates: rates.reducer,
	captcha: captcha.reducer,
	twoFactor: twoFactor.reducer,
	alerts: alerts.reducer,
	[app.CONFIG.STATE_FIELD_NAME]: app.reducer,
});
export default createRootReducer;
