import React, { useState, useMemo } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import {
	R,
	Button,
	FormTextField,
	FormCheckbox,
	Switch,
	FormSwitch,
	useTranslate,
} from '@dev2t/react-ui-kit/src';
import { Typography, Box } from '@material-ui/core';
import { FormDatePicker } from '@components';
import { useAccount } from '@hooks';
import { AccountType } from '@enums';
import { RequireConfirmationCodeItem, RequireConfirmationCodeReason } from '@shared';
import moment from 'moment';

const CreateApiKeyModalForm = (props) => {
	const {
		handleSubmit,
		valid,
		submitting,
		hasConfirmationCodeValue,
	} = props;
	const translate = useTranslate();
	const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
	const mt5Accounts = useAccount(AccountType.MT5);
	const mt5AccountsWithLogin = useMemo(() => (mt5Accounts && mt5Accounts.filter(x => !!x.Login)) || [], [mt5Accounts]);
    const [keyExpirationVisible, setKeyExpirationVisible] = useState(false);
	const [datePickerVal, setDatePickerVal] = useState(maxDate);

	return (
		<form onSubmit={handleSubmit}>
			<Box mb="10px">
				<Field
					component={FormTextField}
					name="Name"
					disableUnderline
					label={translate('api_keys_create_form_description', 'Key description')}
				/>
			</Box>
            <Box>
                <Typography inline="true">
                    <Switch onChange={() => setKeyExpirationVisible(!keyExpirationVisible)}/>
                    <R id="api_keys_create_form_key_expiration" defaultValue="Key expiration (UTC)" />
                </Typography>
                {keyExpirationVisible && (
					<Field
						component={FormDatePicker}
						name="KeyExpiration"
						props={{
							value: datePickerVal,
							onChange: (date) => setDatePickerVal(date),
							asUtc: true,
							minDate: moment.utc(),
							maxDate: moment(maxDate)
						}}
					/>
				)}
			</Box>
            <Box>
                <Typography inline="true">
                    <Field
				    	component={FormSwitch}
				    	name="ReadOnly"
				    />
                    <R id="api_keys_create_form_readonly" defaultValue="Read only" />
                </Typography>
			</Box>
            <Box>
                {mt5AccountsWithLogin.map((acc, index) => (
                    <Box key={`MT5TradeCodesList[${index}]`}>
                        <Typography inline="true">
                            <Field
								component={FormCheckbox}
								name={`MT5TradeCodesList[${index}]`}
								normalize={v => v ? acc.Login : null}
								label={acc.Login}
                            />
                        </Typography>
                    </Box>
                ))}
            </Box>
			<RequireConfirmationCodeItem
				name="ConfirmationCode"
				buttonTitle={<R id="api_keys_generate_key" defaultValue="Generate key" />}
				reason={RequireConfirmationCodeReason.Confirmation}
				disabled={!valid || submitting}
			>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={!valid || submitting || !hasConfirmationCodeValue}
				>
					{submitting ? (
						<CircularProgress size={24} />
					) : (
						<R id="api_keys_generate_key" defaultValue="Generate key" />
					)}
				</Button>
			</RequireConfirmationCodeItem>
		</form>
	);
};

const ReduxFormComponent = reduxForm({
	form: 'CreateApiKeyModalForm',
	validate: (values) => {
		const errors = {};
		if (!values) {
			return errors;
		}
		if (!values.MT5TradeCodesList || !values.MT5TradeCodesList?.some(s => s)) {
            
			errors.MT5TradeCodesList = 'required';
		}
        if (!values.Name || !values.Name?.length > 1) {
            
			errors.Name = 'required';
		}
		return errors;
	},
	onSubmitSuccess: (result, dispatch, props) => {
		if (props.onSuccess && typeof props.onSuccess === 'function') {
			props.onSuccess();
		}
	},
	onSubmitFail: (errors, dispatch, submitError, props) => {
		// eslint-disable-next-line no-underscore-dangle
		if (errors._error && props.onFail) {
			props.onFail();
		}
	},
})(CreateApiKeyModalForm);

const selector = formValueSelector('CreateApiKeyModalForm');
const CreateApiKeyReduxForm = connect((state) => {
	const hasConfirmationCodeValue = selector(state, 'ConfirmationCode');
	return {
		hasConfirmationCodeValue,
	};
})(ReduxFormComponent);

export default CreateApiKeyReduxForm;
