import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	withdrawFunds,
} from '@redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	FormSelect,
	BankLogo,
	Button,
	Divider,
	LimeLogo,
	SvgIcon,
	WithdrawAmount,
} from '@components';
import { constants } from '@config';
import { toUsaMoneyFormat } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import LongArrow from './arrow.svg';
import { SuccessMessage } from '@dev2t/react-ui-kit/src/components';

const styles = theme => ({
	row: {
		display: 'flex',
	},
	amount: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	amountInput: {
		paddingLeft: '70px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	divider: {
		marginTop: '30px',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		marginTop: '45px',
	},
});


const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	return {
		initialValues: {
			bankAccount: ownProps.toBankAccount,
			bankAccountNumber: ownProps.toBankAccount.Number,
			TradeCode: currentTradeAccount.tradeCode,
			ClearerFirm: currentTradeAccount.clearerFirm,
			Amount: ownProps.amount,
		},
		linkedAccounts: userAccounts.selectors.linkedAccounts(state),
		currentTradeAccount,
		currentAccountData: userAccounts.selectors.currentAccountData(state),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		hideWithdrawFundsAchModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsAchModal()),
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});

class AchWithdrawalForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(values, props) {
		const errors = {};
		const cashToWithdraw = AchWithdrawalForm.getCashToWithdraw(props);

		if (!values.bankAccount) {
			errors.bankAccount = 'required';
		}
		if (!values.Amount || values.Amount <= 0) {
			errors.Amount = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Amount) {
				errors.Amount = 'Available amount for withdrawal is less than requested';
			}
		}
		return errors;
	}

	render() {
		const {
			classes,
			actions,
			amount,
			linkedAccounts,
			handleSubmit,
			toBankAccount,
			submitSucceeded,
			currentTradeAccount,
			currentAccountData: {
				submitting,
			},
			...props
		} = this.props;

		const selectedBankAccount = toBankAccount;

		return submitSucceeded ? <SuccessMessage
			onClick={actions.hideWithdrawFundsAchModal}
			text="Banking transaction completed"
			buttonText="Thank you"
		/> :
			(
				<form onSubmit={handleSubmit(withdrawFunds.actions.withdrawAchFundsForm)}>
					<div className={classes.accountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								<LimeLogo />
							</div>
							<Field component={FormSelect} name="TradeCode" label="Account" disabled>
								<MenuItem value={currentTradeAccount.tradeCode}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
									</div>
								</MenuItem>
							</Field>
						</div>
					</div>
					<div className={classes.arrow}>
						{selectedBankAccount &&
							<img alt="Long Arrow Down" src={LongArrow} />
						}
					</div>
					<div className={classes.bankAccountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								{selectedBankAccount &&
								<BankLogo
									bankAccount={selectedBankAccount}
								/>
								}
							</div>
							<Field component={FormSelect} name="bankAccountNumber" label="Bank Account" disabled>
								{
									linkedAccounts &&
									(linkedAccounts.length > 0) &&
									linkedAccounts.map((bankAccount, index) => (
										<MenuItem value={bankAccount.Number} key={index}>
											<div className={classes.inputContainer}>
												<div className={classes.account}>*{bankAccount.displayNumber}</div>
												{bankAccount.Balance &&
													<div className={classes.accountBalance}>
														{toUsaMoneyFormat(bankAccount.Balance)}
													</div>
												}
											</div>
										</MenuItem>
									))
								}
							</Field>
						</div>
					</div>

					<WithdrawAmount
						loading={submitting || false}
						availableAmount={AchWithdrawalForm.getCashToWithdraw(this.props)}
						onClick={() => { this.props.actions.refreshAccountPortfolio(currentTradeAccount.tradeCode); }}
						disabled={amount != null}
					/>
					<div className={classes.divider}>
						<Divider />
					</div>
					<p className={classes.warning}>
						{constants.warningForNonTransferableSecuritiesHolders}
					</p>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={props.invalid || props.submitting}
						>
							{props.submitting ?
								<CircularProgress
									
									size={18}
								/>
								: 'Withdraw'
							}
						</Button>
					</div>
				</form>
			);
	}
}

AchWithdrawalForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
	fromUserAccount: PropTypes.object.isRequired, // not used
	toBankAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'withdrawFundsAchForm',
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess('ACH');
			}
		},
		validate: AchWithdrawalForm.validate,
	}),
)(AchWithdrawalForm);
