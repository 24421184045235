import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	change,
	formValueSelector,
} from 'redux-form';
import {
	Button,
	ProgressContainer,
	SelectField,
} from '@components';
import { FormInput, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
		marginBottom: '40px',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '25px',
		background: theme.palette.background.paper,	
		borderRadius: '8px',
	},
	button: {
		paddingTop: '12px',
	},
	header: {
		textAlign: 'center',
		margin: '0px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.background.paper,
		},
	}
});

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}

const CHANGE_PROFILE_EMPLOYMENT_FORM = 'ChangeProfileEmploymentForm';
const mapStateToProps = (state) => {
	const modalParams = profile.selectors.modals(state)[ModalPopups.CHANGE_EMPLOYMENT];
	const userProfile = modalParams || profile.selectors.profile(state);

	const employmentProfile = userProfile.EmploymentProfile;
	const initialValues = (!userProfile.submitting && !userProfile.error) ? {
		clientId: userProfile.ClientId,
		guid: userProfile.Guid,
		stateAbbr: employmentProfile.Address.StateAbbr,
		zipCode: employmentProfile.Address.ZipCode,
		city: employmentProfile.Address.City,
		streetAddress: employmentProfile.Address.StreetAddress,
		house: employmentProfile.Address.House,
		building: employmentProfile.Address.Building,
		apartment: employmentProfile.Address.Apartment,
		country: employmentProfile.Address.Country,
		position: employmentProfile.Position,
		years: employmentProfile.Years,
		natureOfBusiness: employmentProfile.NatureOfBusiness,
		company: employmentProfile.Company,
		explanationSourceOfFunding: employmentProfile.ExplanationSourceOfFunding,
		status: employmentProfile.Status,
	} : {};
	return {
		initialValues,
		getFormValue: fieldName => formValueSelector(CHANGE_PROFILE_EMPLOYMENT_FORM)(state, fieldName),
		employmentProfile,
		countries: profile.selectors.getCountries(state),
		usaStates: profile.selectors.getUsaStates(state),
		profileSubmitting: !!userProfile.submitting,
		pendingOrder: userProfile.PendingOrders.Employment,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshCountriesList: () => dispatch(profile.actions.getCountries.request()),
		refreshUsaStatesList: () => dispatch(profile.actions.getUsaStates.request()),
		setFormField: (fieldName, value = null) => dispatch(change(CHANGE_PROFILE_EMPLOYMENT_FORM, fieldName, value)),
	},
});
class ChangeEmploymentModal extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.country) {
			errors.country = 'required';
		}

		if (!values.streetAddress) {
			errors.streetAddress = 'required';
		}

		if (!values.city) {
			errors.city = 'required';
		}

		if (!values.zipCode) {
			errors.zipCode = 'required';
		}
		if (!values.stateAbbr && values.country === 'US') {
			errors.stateAbbr = 'required';
		}
		if (!values.explanationSourceOfFunding) {
			errors.explanationSourceOfFunding = 'required';
		}
		if (!values.company) {
			errors.company = 'required';
		}
		if (!values.natureOfBusiness) {
			errors.natureOfBusiness = 'required';
		}
		if (!values.years) {
			errors.years = 'required';
		}
		if (!values.position) {
			errors.position = 'required';
		}
		if (!values.status) {
			errors.status = 'required';
		}
		return errors;
	}

	componentDidMount() {
		const { countries, usaStates, actions } = this.props;
		if (!countries) {
			actions.refreshCountriesList();
		}
		if (!usaStates) {
			actions.refreshUsaStatesList();
		}
	}

	checkYears = (value) => {
		if (value < 1) {
			return 1;
		}
		return value;
	}
	sortUsFirst = (a, b) => {
		if (a.CodeLat2 === 'US') {
			return -1;
		}
		if (b.CodeLat2 === 'US') {
			return 1;
		}
		if (a.Name === b.Name) {
			return 0;
		}
		return a.Name < b.Name ? -1 : 1;
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			pristine,
			profileSubmitting,
			pendingOrder,
			submitting,
			submitSucceeded,
			employmentProfile: EmploymentProfile,
			initialValues,
			getFormValue,
			countries,
			usaStates,
		} = this.props;

		const employmentStatus = getFormValue('status');
		return (
			<RequireSignatureModal
				title="Change Employment"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_EMPLOYMENT}`}
			>
				{submitSucceeded && <SuccessMessage onClick={onClose} text="Your request to change employment is processed." />}
				{!submitSucceeded && !profileSubmitting && pendingOrder &&
					<ErrorMessage
						onClick={onClose}
						text="Another request for employment change is in progress. Please try again later."
					/>
				}
				{!submitSucceeded && !pendingOrder &&
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileEmploymentFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="Please enter your new employment status"
									name="status"
									items={[{ value: 'Employed', text: 'Employed' },
										{ value: 'Self-Employed', text: 'Self-Employed' },
										{ value: 'Retired', text: 'Retired' },
										{ value: 'Unemployed', text: 'Unemployed' },
										{ value: 'Student', text: 'Student' },
										{ value: 'Employed Student', text: 'Employed Student' },
									]}
									value={valueOrEmpty(EmploymentProfile.Status)}
								/>
							</div>
							{(employmentStatus === 'Employed'
							|| employmentStatus === 'Self-Employed'
							|| employmentStatus === 'Student'
							|| employmentStatus === 'Employed Student') &&
							<div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="company"
										placeholder="Please enter your new company name"
										label="Company/ School Name"
										value={EmploymentProfile.Company}
									/>
								</div>
								{employmentStatus !== 'Student' &&
								<div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="position"
											placeholder="Please enter your new position"
											label="Position"
											value={EmploymentProfile.Position}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="natureOfBusiness"
											placeholder="Please enter your new nature of business"
											label="Nature of Business"
											value={EmploymentProfile.NatureOfBusiness}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="years"
											type="number"
											normalize={this.checkYears}
											placeholder="Please enter your new years"
											label="Years"
											value={EmploymentProfile.Years}
										/>
									</div>
									<h3 className={classes.header}>Please enter your new company address</h3>
									<div className={classes.inputSelectItem}>
										<SelectField
											label="Country"
											name="country"
											items={
												(countries && countries
													.sort(this.sortUsFirst)
													.map(cntr => ({ value: cntr.CodeLat2, text: cntr.Name }))) || []}
											value={valueOrEmpty(initialValues.country)}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="streetAddress"
											label="Street address"
											value={EmploymentProfile.StreetAddress}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="house"
											label="House"
											value={EmploymentProfile.Apartment}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="building"
											label="Building"
											value={EmploymentProfile.Apartment}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="apartment"
											label="Apt/Suite"
											value={EmploymentProfile.Apartment}
										/>
									</div>
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="city"
											label="City"
											value={EmploymentProfile.City}
										/>
									</div>
									{getFormValue('country') === 'US' &&
										<div className={classes.inputSelectItem}>
											<SelectField
												label="State"
												name="stateAbbr"
												items={
													(usaStates && usaStates.map(st => ({ value: st.Abbr, text: st.Name }))) || []}
												value={valueOrEmpty(initialValues.stateAbbr)}
											/>
										</div>
									}
									<div className={classes.inputItem}>
										<Field
											component={FormInput}
											name="zipCode"
											label="Zip Code"
											value={EmploymentProfile.ZipCode}
										/>
									</div>
								</div>
								}
							</div>
							}
							{(employmentStatus === 'Retired' || employmentStatus === 'Unemployed') &&
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="explanationSourceOfFunding"
										placeholder="New explanation & source of funding"
										label="Explanation & Source of Funding"
										value={EmploymentProfile.ExplanationSourceOfFunding}
									/>
								</div>
							}
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting || pristine}
								>
									{submitting ?
										<CircularProgress
											
											size={18}
										/>
										: 'Change Employment'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeEmploymentModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	countries: PropTypes.array,
	usaStates: PropTypes.array,
	getFormValue: PropTypes.func.isRequired,
};
ChangeEmploymentModal.defaultProps = {
	countries: undefined,
	usaStates: undefined,
};
export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CHANGE_PROFILE_EMPLOYMENT_FORM,
		validate: ChangeEmploymentModal.validate,
		enableReinitialize: true,
	}),
)(ChangeEmploymentModal);
