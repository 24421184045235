import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ProgressContainer } from '@components';
import { R, Modal, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import { nontradingOrders, userAccounts } from '@redux';
import {
	CYOpenAccountChoosePlatform,
	CYOpenAccountChooseType,
	CYOpenAccountChooseLeverage,
	CYOpenAccountChooseCurrency,
} from './components';
import { Platform } from '../../../../../../../../utils/platforms';

const CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID = 'CYNonTradingOpenAccountModal';
const CYNonTradingOpenAccountModal = ({ onClose }) => {
	const [step, setStep] = useState('chooseplatform');
	const [error, setError] = useState('');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	return (
		<Modal
			title={<R id="common_title_add_live_accounts" defaultValue="Open additional live account" />}
			onClose={onClose}
			maxWidth="md"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded'
						&& (
							<SuccessMessage
								onClick={onClose}
								text={<R id="common_open_account_completed" defaultValue="Account has been successfully opened" />}
							/>
						)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={error || <R id="common_open_account_failed" defaultValue="Could not complete new account" />}
							/>
						)}
					{step === 'chooseplatform' && (
						<CYOpenAccountChoosePlatform
							destroyOnUnmount={false}
							forceUnregisterOnUnmount
							form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
							enableReinitialize
							onSubmit={(result) => {
								setStep(
									result.OpenAccountOut.SelectedPlatform === Platform.MT4
									? 'choosetype'
									: 'chooseleverage'
								)
							}}
						/>
					)}
					{step === 'choosetype'
						&& (
							<CYOpenAccountChooseType
								destroyOnUnmount={false}
								form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
								onSubmit={() => setStep('chooseleverage')}
							/>
						)}
					{step === 'chooseleverage'
						&& (
							<CYOpenAccountChooseLeverage
								destroyOnUnmount={false}
								form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
								onSubmit={nontradingOrders.actions.sendNonTradingOpenAccount}
								onSuccess={() => setStep('succeeded')}
								onSubmitFail={err => { setError(err._error); setStep('failed'); }}
								onSubmitSuccess={() => setStep('succeeded')}
							/>
						)}
					{step === 'choosecurrency'
						&& (
							<CYOpenAccountChooseCurrency
								destroyOnUnmount={false}
								form={CY_NON_TRADING_OPEN_ACCOUNT_FORM_ID}
								onSubmit={nontradingOrders.actions.sendNonTradingOpenAccount}
								onSuccess={() => setStep('succeeded')}
								onSubmitFail={err => { setError(err._error); setStep('failed'); }}
								onSubmitSuccess={() => setStep('succeeded')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default CYNonTradingOpenAccountModal;
