import { useMemo } from 'react';
import { useTranslate } from '@dev2t/react-ui-kit/src/components';
import { useBalance } from '@hooks';

export const useAvailableCurrencies = (tradeCode) => {
	const balance = useBalance(tradeCode);
	const translate = useTranslate();
	const availableCurrencies = useMemo(() => {
		if (!balance) {
			return [];
		}
		const templates = [
			{
				SelectTarget: 'BITCOIN',
				Title: translate('orders_selectmethod_bitcoin_title', 'Bitcoin Wallet'),
				AccountCurrency: 'BTC',
				Icon: 'BitcoinIcon',
			},
			{
				SelectTarget: 'ETHER',
				Title: translate('orders_selectmethod_ethereum_title', 'Ethereum'),
				AccountCurrency: 'ETH',
				Icon: 'EtheriumIcon',
			},
			{
				SelectTarget: 'TETHER',
				Title: translate('orders_selectmethod_tether_title', 'Tether'),
				AccountCurrency: 'USDT',
				Icon: 'USDTIcon',
			},
			{
				SelectTarget: 'RIPPLE',
				Title: translate('orders_selectmethod_ripple_wallet_title', 'Ripple'),
				AccountCurrency: 'XRP',
				Icon: 'XRPIcon',
			},
		];

		return templates.filter(x => balance.some(b => b.CurrencyCode === x.AccountCurrency));
	}, [translate, balance]);
	return availableCurrencies;
};
