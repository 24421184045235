import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile } from '@redux';
import { useSelector } from 'react-redux';
import {
	ProgressContainer,
} from '@components';
import { RequireSignatureModal } from '../../..';
import { SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import ChangeMarketDataSubscriberStatusForm from './components/ChangeMarketDataSubscriberStatusForm';

const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	return {
		loading: userProfile.submitting,
		pendingOrder: !userProfile.submitting && userProfile.PendingOrders.MarketDataStatus,
	};
};

const ChangeMarketDataSubscriberStatusModal = (props) => {
	const [submitSucceeded, setSubmitSucceeded] = useState(false);
	const { onClose } = props;

	const {
		pendingOrder,
		loading,
	} = useSelector(mapStateToProps);

	const OnSuccess = () => setSubmitSucceeded(true);

	return (
		<RequireSignatureModal
			title="Change Market Data Subscriber Status"
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
			className={`QA-${ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL}`}
		>
			{submitSucceeded &&
				<SuccessMessage
					onClick={onClose}
					text="Your request to change market data subscriber status is processed."
				/>}
			{!submitSucceeded && !loading && pendingOrder &&
				<ErrorMessage
					onClick={onClose}
					text="Another request for market data subscriber status change is in progress. Please try again later."
				/>
			}
			{!submitSucceeded && !pendingOrder &&
				<ProgressContainer submitting={loading} disableCheckChildren>
					<ChangeMarketDataSubscriberStatusForm
						OnSuccess={OnSuccess}
					/>
				</ProgressContainer>
			}
		</RequireSignatureModal>
	);
};

ChangeMarketDataSubscriberStatusModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default ChangeMarketDataSubscriberStatusModal;
