import { createApiActions } from '../extensions';


const actions = {
	agentRegistration: createApiActions('AGENT/REGISTRATION'),
	partnerCabinetRedirect: createApiActions('AGENT/PARTNER_CABINET_REDIRECT'),
	SET_PARTNER_CABINET_REDIRECT_BLOCK_EXPIRATION: 'SET_PARTNER_CABINET_REDIRECT_BLOCK_EXPIRATION',
	setPartnerCabinetRedirectBlockExpiration:  (payload) => ({
		type: actions.SET_PARTNER_CABINET_REDIRECT_BLOCK_EXPIRATION,
		payload
	}),
};

export default actions;
