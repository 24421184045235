import React from 'react';
import { R, Modal, Button } from '@dev2t/react-ui-kit/src';
import { ModalPopups } from '@enums';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';

const NewAgentWaitingModal = ({ onClose }) => {
	const theme = useTheme();
	const verticalSpacing = theme.spacing(2) + "px";

	const defaultTitle = "Please wait";
	const defaultMessage = 'Your agent profile is syncing now. Some of the functionality of the account may be temporarily unavailable. Please try again later.';

	return (
		<Modal
			title={<R id="new_agent_wait_title" defaultValue={defaultTitle}/>}
			onClose={onClose}
			className={`QA-${ModalPopups.NEW_AGENT_WAITING_MODAL}`}
		>
			<R id="new_agent_wait_notification" defaultValue={defaultMessage} />
			<Box mt={verticalSpacing}>
				<Button
					color="primary"
					variant="contained"
					fullWidth
					onClick={onClose}
				>
					<R id="shared_ok" defaultValue="OK" />
				</Button>
			</Box>
		</Modal>
	);
};

export default NewAgentWaitingModal;
