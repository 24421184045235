import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TxAuthWidget from '@finam/tx-auth-widget';
import { constants, env, getAllowedLanguages, theme } from '@config';
import { auth, app } from '@redux';
import {
	cabinetLog,
	globalState,
	getLanguageCookie,
	setLanguageCookie,
} from '@global';
import { ErrorCatcher, FullScreenProgressContainer } from '@components';
import { addLocaleData } from 'react-intl';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import * as qs from 'query-string';
import intlEn from 'react-intl/locale-data/en';
import configureStore from './core/redux/store';
import RootRoutes from './views/RootRoutes';
import { Snackbar } from './views/DashboardView/components';
import { TranslateContextProvider } from '@dev2t/react-ui-kit/src';
import { LocalStorageKey } from '@enums';

const { store, history } = configureStore();

const useStyles = makeStyles({
	widgetButtonLme: {
		backgroundColor: 'rgb(96, 154, 252) !important',
		'&:hover:not(.disabled):not(.loading)': {
			backgroundColor: '#4A88F2 !important',
		},
		'&[id=txauth-widget-iamnot-button]:not(.disabled):not(.loading)': {
			color: '#4A88F2 !important',
			background: 'rgba(108,162,255,0.16) !important',
		},
		'&[id=txauth-widget-iamnot-button]:hover:not(.disabled):not(.loading)': {
			background: 'background: rgba(108,162,255,0.24) !important',
		},

	},
	widgetButtonJ2tx: {
		backgroundColor: 'rgb(96, 154, 252) !important',
		'&:hover:not(.disabled):not(.loading)': {
			backgroundColor: '#4A88F2 !important',
		},
		'&[id=txauth-widget-iamnot-button]:not(.disabled):not(.loading)': {
			color: '#4A88F2 !important',
			background: 'rgba(108,162,255,0.16) !important',
		},
		'&[id=txauth-widget-iamnot-button]:hover:not(.disabled):not(.loading)': {
			background: 'background: rgba(108,162,255,0.24) !important',
		},

	},
	widgetTextField: {
		'&:not(.error).hasFocus .ui-input__root': {
			borderColor: '#609AFC !important',
			boxShadow: '0px 0px 0px 3px rgb(108 162 255 / 24%) !important',
		},
		'&:not(.error).hasFocus label.active': {
			color: '#4A88F2 !important',
		},
	},
	widgetTextFieldJ2tx: {
		'&:not(.error).hasFocus .ui-input__root': {
			borderColor: '#609AFC !important',
			boxShadow: '0px 0px 0px 3px rgb(108 162 255 / 24%) !important',
		},
		'&:not(.error).hasFocus label.active': {
			color: '#4A88F2 !important',
		},
	},
});

const setMomentLocale = language => {
	switch (language) {
		case 'zh':
			moment.locale('zh-cn');
			break;
		case 'en':
			moment.locale('en-us');
			break;
		default:
			moment.locale(language);
			break;
	}
};

const loadLocaleData = language => {
	const lang = getAllowedLanguages().find(x => x.language === language);
	if (lang) {
		addLocaleData([...lang.intlLocale]);
		setMomentLocale(language);
	} else {
		// defaults
		addLocaleData([...intlEn]);
		setMomentLocale('en');
	}
};

const useResizeActions = () => {
	const onResize = () => {
		let resolution = app.CONFIG.RESOLUTIONS_ENUM.xl;
		const width = window.innerWidth;
		const { values } = theme.breakpoints;
		if (width < values.xl) resolution = app.CONFIG.RESOLUTIONS_ENUM.lg;
		if (width < values.lg) resolution = app.CONFIG.RESOLUTIONS_ENUM.md;
		if (width < values.md) resolution = app.CONFIG.RESOLUTIONS_ENUM.sm;
		if (width < values.sm) resolution = app.CONFIG.RESOLUTIONS_ENUM.xs;

		if (app.selectors.resolution(store.getState()) !== resolution) {
			store.dispatch(app.actions.setResolution(resolution));
		}
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize();
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);
};

const useApiVersionSynchronization = () => {
	const checkActualVersion = () => {
		const checkTime = localStorage.getItem(LocalStorageKey.VerStm);
		const curTime = moment().format('YYYYMMDD');
		if (!checkTime || checkTime !== curTime) {
			localStorage.setItem(LocalStorageKey.VerStm, curTime);

			// use globalState if localStorage not worked
			if (!globalState.version) {
				// check ui version
				store.dispatch(app.actions.checkMinVersionRequest());
				globalState.version = true;
			}
		}
	};
	useEffect(() => {
		checkActualVersion();
	}, []);
};

const useGoogleAnalytics = () => {
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			if (env.GA_TRACKING_ID) {
				ReactGA.initialize(env.GA_TRACKING_ID, { debug: true });
				ReactGA.set({ dimension2: 'cabinet' });
				ReactGA.pageview(window.location.pathname + window.location.search);
			}
			if (env.GTM_DATA_LAYER) {
				const tagManagerArgs = {
					gtmId: env.GTM_DATA_LAYER,
				};
				TagManager.initialize(tagManagerArgs);
			}
		}
	}, []);
};

const useAuth = () => {
	const onSubscribeJWT = resp => {
		cabinetLog((resp && resp.token) ? 'has token' : 'no token');
		if (resp && resp.token) {
			globalState.localToken = resp.token;
			const person = resp.session && resp.session.person;
			const loginFromJwt = person && (
				person.login
				|| person.email
				|| (person.verifiedPhone && person.verifiedPhone.replace(new RegExp('\\D', 'g'), ''))
			);
			cabinetLog('clients', globalState.loginFromJwt, loginFromJwt);
			const isRenew = globalState.loginFromJwt === loginFromJwt;
			if (!isRenew) {
				globalState.loginFromJwt = loginFromJwt;
				window.Raven.setUserContext({
					login: loginFromJwt,
				});
				// send login signal for clear all prev user data
				store.dispatch(auth.actions.loginUser());
			}
		} else {
			globalState.localToken = null;
			if (globalState.loginFromJwt) {
				globalState.loginFromJwt = null;
				window.Raven.setUserContext();
			}
			if (history.location.pathname.indexOf('/reset') !== 0) {
				cabinetLog('push on /login');
				history.push('/login');
			} else {
				// go to reset or reset password
				history.push(history.location.pathname);
			}
		}
	};

	const classes = useStyles();
	useEffect(() => {
		if (!env.useLocalAuth) {
			TxAuthWidget.new({
				appName: `${env.widgetVariant}-cab`,
				appVersion: env.version,
				lang: 'en',
				theme: 'light',
				env: env.widgetEnv,
				variant: env.widgetVariant,
				provider: env.widgetProvider,
				parseUrl: true,
				title: ' ',
				uiSettings: {
					classNames: {
						button: classNames({
							'QA-SignIn': true,
							[classes.widgetButtonJ2tx]: env.firm === constants.LIME_LME,
							[classes.widgetButtonLme]: env.firm === constants.FIRM_J2TX,

						}),
						textField: classNames({
							[classes.widgetTextField]: env.firm === constants.LIME_LME,
							[classes.widgetTextFieldJ2tx]: env.firm === constants.FIRM_J2TX,
						}),
					},
					hideLogo: true,
				},
			}).then(widget => {
				widget.subscribeJWT(onSubscribeJWT);
				// put widget to redux, because it used on the loginForm view
				widget.subscribeOnLogout(() => {
					globalState.localToken = null;
				});
				store.dispatch(auth.actions.setWidget(widget));
			});
		}
	}, []);
};

const useShowDicWordsKeys = () => {
	const [showDicWordsRes, setShowDicWordsRes] = useState(false);
	const getShowDicWords = () => store.getState().app.showDicwordsKeys === '1';
	const analyzeDisplayDicwordsKeys = () => {
		let showDicWords = getShowDicWords();
		const parsed = qs.parse(window.location.search);
		const reShow = new RegExp('^showDicWord(s?)key(s?)$', 'i');
		const reHide = new RegExp('^hideDicWord(s?)key(s?)$', 'i');
		const parsedShow = Object.keys(parsed).some(k => reShow.test(k));
		const parsedHide = Object.keys(parsed).some(k => reHide.test(k));
		if (parsedShow && !showDicWords) {
			showDicWords = true;
			store.dispatch(app.actions.showDicwords());
		} else if (parsedHide && showDicWords) {
			showDicWords = false;
			store.dispatch(app.actions.hideDicwords());
		}
		return showDicWords;
	};

	useEffect(() => {
		setShowDicWordsRes(analyzeDisplayDicwordsKeys());
	}, []);

	return showDicWordsRes;
};

const useLanguages = () => {
	const [initialized, setInitialized] = useState(false);

	const onChangeLanguage = () => {
		const language = app.selectors.language(store.getState());

		if (!language || window.app_locale === language) {
			return;
		}

		window.app_locale = language;
		setLanguageCookie(language);
		i18next.changeLanguage(language);
		setMomentLocale(language);
	};

	useEffect(() => {
		const lang = getLanguageCookie();
		window.app_locale = lang;
		loadLocaleData(lang);
		const enableTranslateBackend = !!env.allowedLangs;
		if (enableTranslateBackend) {
			store.dispatch(app.actions.changeLanguage.request(lang));
		}
		const i18nParams = {
			lng: lang,
			fallbackLng: 'en',
			supportedLngs: getAllowedLanguages().map(item => item.language),
			interpolation: {
				escapeValue: false,
				prefix: '{',
				suffix: '}',
			},
			react: {
				useSuspense: false,
				wait: false,
			},
			partialBundledLanguages: true,
		};
		if (enableTranslateBackend) {
			i18next
				.use(Backend) // passes i18n down to react-i18next
				.use(initReactI18next)
				.init({
					...i18nParams,
					backend: {
						loadPath: env.languagesServerPath,
						parse(data, l) {
							const langAllowed = getAllowedLanguages().find(x => x.language === l);
							return { ...JSON.parse(data), ...langAllowed.sharedTranslate };
						},
					},
				})
				.then(() => setInitialized(true));

			// reload i18n dicwords
			if (enableTranslateBackend && env.reloadDicwordsPeriod) {
				setInterval(() => {
					i18next.reloadResources();
				}, env.reloadDicwordsPeriod);
			}
		} else {
			i18next
				.use(initReactI18next)
				.init({
					...i18nParams,
					resources: {
						en: { translation: {} },
						ru: { translation: {} },
					},
				})
				.then(() => setInitialized(true));
		}

		// хук снаружи редакс Provider, поэтому мы не можем использовать useSelector внутри
		store.subscribe(onChangeLanguage);
	});
	return initialized;
};

const App = () => {
	useResizeActions();
	useApiVersionSynchronization();
	useGoogleAnalytics();
	useAuth();
	const showDicWords = useShowDicWordsKeys();
	const langInitialized = useLanguages();
	return (
		<>
			<Provider store={store}>
				<TranslateContextProvider showDicwordsKeys={showDicWords}>
					<MuiThemeProvider theme={theme}>
						<CssBaseline />
						<ConnectedRouter history={history}>
							<ErrorCatcher>
								<FullScreenProgressContainer submitting={!langInitialized} size={96}>
									<RootRoutes />
									<Snackbar />
								</FullScreenProgressContainer>
							</ErrorCatcher>
						</ConnectedRouter>
					</MuiThemeProvider>
				</TranslateContextProvider>
			</Provider>
		</>
	);
};

export default App;
