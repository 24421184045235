import { fork, takeEvery, call } from 'redux-saga/effects';
import { BaseGetSagaHandler } from '@redux';
import actions from './actions';
import { withdrawWidgetPostRequest } from '@api';

function* postRequestSaga({ 
	payload: { 
		resolveCallback, 
		rejectCallback,
		requestPayload,
		errorText,
	} 
}) {
	yield BaseGetSagaHandler({
		apiMethod: withdrawWidgetPostRequest,
		errorText: errorText,
		handler: actions.post,
		defaults: {
			resolveCallback,
			rejectCallback,
		}
	}, { payload: requestPayload });
}

function* postRequestSuccessSaga({ payload: { request: { resolveCallback }, response } }) {
	yield call(resolveCallback, response);
}

function* watchPostRequest() {
	yield takeEvery(actions.post.REQUEST, postRequestSaga);
}

function* watchPostRequestSuccess() {
	yield takeEvery(actions.post.SUCCESS, postRequestSuccessSaga);
}

// Root saga
const rootSaga = [
	fork(watchPostRequest),
	fork(watchPostRequestSuccess),
];

export default {
	rootSaga,
};
