import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	ProgressContainer,
	
} from '@components';
import { R,
	SuccessMessage,
	ErrorMessage,
	Modal,
} from '@dev2t/react-ui-kit/src';
import { constants } from '@config';
import { ordersCy, userAccounts } from '@redux';
import CYWithdrawalBankAmountForm from './CYWithdrawalBankAmountForm';
import CYWithdrawalBankConfirmForm from './CYWithdrawalBankConfirmForm';

function getTarget(currency) {
	switch (currency) {
		case 'USD':
			return 'USD';
		case 'EUR':
			return 'EUR';
		case 'KRW':
			return 'KOREANBANK';
		case 'MYR':
			return 'MALAYSIANBANK';
		case 'NGN':
			return 'NIGERIANBANK';
		case 'KES':
			return 'KENYANBANK';
		case 'IDR':
			return 'INDONESIANBANK';
		case 'BTC':
			return 'BITCOIN';
		case 'ETH':
			return 'ETHER';
		case 'USDT':
			return 'TETHER';
		case 'XRP':
			return 'RIPPLE';
		case 'LTC':
			return 'LITECOIN';

		default:
			throw new Error(`impossible currency for withdrawal: ${currency}`);
	}
}

// const CY_WITHDRAWAL_KOREANBANK_FORM_ID = 'CYWithdrawalKoreanBankForm';
const CYWithdrawalBankModal = ({
	form, title, onClose, currency, target, requireIFSC
}) => {
	const [step, setStep] = useState('amountform');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	// provide initial values into the form
	const account = useSelector(userAccounts.selectors.currentTradeAccount);

	const platform = useSelector(userAccounts.selectors.accounts)
		.find(x => x.TradeCode === account.tradeCode && x.ClearingFirm === account.clearerFirm)
		.Platform;

	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: account.tradeCode,
			ClearingFirm: account.clearerFirm,
		},
		EMoneyOut: {
			SelectedAccount: `${account.tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: 'USD',
			AccountCurrency: currency,
			Comment: '',
			Reason: constants.MoneyOutReason.NeedMoney,
			ReasonComment: '',
			SelectTarget: target ?? getTarget(currency),
			TargetAccount: '',
		},
	};

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);

	return (
		<Modal
			title={title}
			onClose={onClose}
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded'
						&& (
							<SuccessMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
							/>
						)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
							/>
						)}
					{step === 'amountform'
						&& (
							<CYWithdrawalBankAmountForm
								initialValues={initialValues}
								destroyOnUnmount={false}
								forceUnregisterOnUnmount
								form={form}
								enableReinitialize
								requireIFSC={requireIFSC}
								// forceUnregisterOnUnmount
								// enableReinitialize
								onSubmit={handleGotoPage('confirmform')}
								onSuccess={() => setStep('confirmform')}
								onSubmitFail={() => setStep('failed')}
							/>
						)}
					{step === 'confirmform'
						&& (
							<CYWithdrawalBankConfirmForm
							// enableReinitialize
							// forceUnregisterOnUnmount
								form={form}
								onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
								onSuccess={() => setStep('succeeded')}
								onSubmitFail={() => setStep('failed')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

CYWithdrawalBankModal.propTypes = {
	form: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.object.isRequired,
	currency: PropTypes.string.isRequired,
	target: PropTypes.string,
	requireIFSC: PropTypes.bool,
};

export default CYWithdrawalBankModal;
