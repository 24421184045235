import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userAccounts, withdrawFunds } from '@redux';
import { ProgressContainer } from '@components';
import { R, Modal, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import CYTransferBetweenAccountsForm from './components/CYTransferBetweenAccountsForm';


const styles = {};

const mapStateToProps = state => ({
	loading: userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading,
});


class CYTransferBetweenAccountsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 'openform',
		};
	}

	onSuccess = () => {
		this.setState({ step: 'succeeded' });
	}

	onFail = () => this.setState({ step: 'failed' });

	render() {
		const {
			onClose,
			loading,
		} = this.props;

		const { step } = this.state;
		return (
			<Modal
				title={<R defaultValue="Transfer between accounts" id="common_transfer_between_accounts" />}
				onClose={onClose}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{step === 'succeeded' &&
							<SuccessMessage onClick={onClose} text={<R id="shared_transfer_between_accounts_completed" defaultValue="Transfer between accounts completed" />} />
						}
						{step === 'failed' &&
							<ErrorMessage onClick={onClose} text={<R id="shared_could_not_complete_transfer" defaultValue="Could not complete transfer" />} />
						}
						{step === 'openform' &&
							<CYTransferBetweenAccountsForm
								enableReinitialize
								forceUnregisterOnUnmount
								onSubmit={withdrawFunds.actions.transferBetweenAccountsSubmitForm}
								onSuccess={this.onSuccess}
								onSubmitFail={this.onFail}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

CYTransferBetweenAccountsModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CYTransferBetweenAccountsModal);
