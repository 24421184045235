import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalPopups } from '@enums';
import { ProgressContainer } from '@components';
import { R, Modal, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src/components';
import { ordersCy, userAccounts, app } from '@redux';
import { useJ2txCommission } from '@hooks';
import { getUSDTProtocol } from '@global';
import { constants } from '@config';
import { formValueSelector } from 'redux-form';
import {
	J2TXWithdrawalAmountForm,
	J2TXWithdrawalConfirmForm,
} from './components';
import { CONFIRMATION_TYPE } from '@shared/RequireConfirmationCodeItem/RequireConfirmationCodeItem';


function getTarget(currency) {
	switch (currency) {
		case 'USD':
			return 'USD';
		case 'EUR':
			return 'EUR';
		case 'BTC':
			return 'BITCOIN';
		case 'ETH':
			return 'ETHER';
		case 'USDT':
			return 'TETHER';
		case 'XRP':
			return 'RIPPLE';
		case 'LTC':
			return 'LITECOIN';
		default:
			throw new Error(`impossible currency for j2tx wallet withdraw: ${currency}`);
	}
}

const J2TXWalletWithdrawalModal = ({ onClose }) => {
	const [step, setStep] = useState('amountform');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);
	const language = useSelector(app.selectors.language);


	// commission for form validation
	const EMoneyOut = useSelector(state => formValueSelector(ModalPopups.J2TX_WALLET_WITHDRAWAL)(state, 'EMoneyOut'));
	const NonOrderData = useSelector(state => formValueSelector(ModalPopups.J2TX_WALLET_WITHDRAWAL)(state, 'NonOrderData'));
	const commission = useJ2txCommission(EMoneyOut?.AccountCurrency, getUSDTProtocol(EMoneyOut?.TargetAccount));
	
	// provide initial values into the form
	const { tradeCode, currencyCode } = useSelector(userAccounts.selectors.modals)[ModalPopups.J2TX_WALLET_WITHDRAWAL];

	const clearerFirm = 'J2TX';

	const platform = useSelector(userAccounts.selectors.accounts)
		.find(x => x.TradeCode === tradeCode && x.ClearingFirm === clearerFirm)
		.Platform;

	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: tradeCode,
			ClearingFirm: clearerFirm,
			ConfirmationType: CONFIRMATION_TYPE.Sms,
		},
		EMoneyOut: {
			SelectedAccount: `${tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: currencyCode,
			AccountCurrency: currencyCode,
			Comment: '',
			Reason: constants.MoneyOutReason.NeedMoney,
			ReasonComment: '',
			SelectTarget: getTarget(currencyCode),
			TargetAccount: '',
		},
	};

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);
	const isRestrictedWithdrawal = NonOrderData?.ConfirmationType === CONFIRMATION_TYPE.Email && language === 'zh';
	return (
		<Modal
			title={<R id="shared_withdrawal_funds" defaultValue="Withdrawal Funds" />}
			onClose={onClose}
			maxWidth="sm"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' && (
						<SuccessMessage
							onClick={onClose}
							text={isRestrictedWithdrawal ?
								<R
									id="common_wallet_temporary_restricted"
									defaultValue="There is a temporary (72 hours) restriction on withdrawal of funds on your account for security purposes due to the fact that the order was confirmed by a code from Email."
								/> :
								<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
						/>
					)}
					{step === 'failed'
						&& (
							<ErrorMessage
								onClick={onClose}
								text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
							/>
						)}
					{step === 'amountform'
						&& (
							<J2TXWithdrawalAmountForm
								initialValues={initialValues}
								destroyOnUnmount={false}
								commission={commission}
								form={ModalPopups.J2TX_WALLET_WITHDRAWAL}
								enableReinitialize
								onSubmit={handleGotoPage('confirmform')}
								onSuccess={() => setStep('confirmform')}
								onFail={() => setStep('failed')}
							/>
						)}
					{step === 'confirmform'
						&& (
							<J2TXWithdrawalConfirmForm
								form={ModalPopups.J2TX_WALLET_WITHDRAWAL}
								destroyOnUnmount={false}
								onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
								onSuccess={() => setStep('succeeded')}
								onFail={() => setStep('failed')}
							/>
						)}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default J2TXWalletWithdrawalModal;
