import { createApiActions } from '@dev2t/react-ui-kit/src';

const prefix = 'NOTIFICATIONS';

const createHandlerActions = (prefix) => ({
	SET_HANDLER: `${prefix}_SET_HANDLER`,
	setHandler: (handler) => ({
		type: `${prefix}_SET_HANDLER`,
		payload: {
			handler,
		},
	}),
	CALL_HANDLER: `${prefix}_CALL_HANDLER`,
	callHandler: () => ({
		type: `${prefix}_CALL_HANDLER`,
		payload: {},
	}),
})

const actions = {
	SHOW_MESSAGE_MODAL: `${prefix}/SHOW_MESSAGE_MODAL`,
	HIDE_MESSAGE_MODAL: `${prefix}/HIDE_MESSAGE_MODAL`,

	showMessageModal: message => ({
		type: actions.SHOW_MESSAGE_MODAL,
		payload: {
			message,
		},
	}),
	hideMessageModal: () => ({
		type: actions.HIDE_MESSAGE_MODAL,
	}),

	getNotifications: createApiActions(`${prefix}/GET_NOTIFICATIONS`),
	getBanners: createApiActions(`${prefix}/GET_BANNERS`),

	reloadNotifications: createHandlerActions(`${prefix}/RELOAD_NOTIFICATIONS_HANDLER`),
};

export default actions;
