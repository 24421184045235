import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { useDispatch } from 'react-redux';
import { app  } from '@redux';
import { ModalPopups } from '@enums';

const useStyles = makeStyles(theme => ({
	root: {
		...theme.customstyles.button,
		color: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		marginTop: '20px',
		marginBottom: '20px',
	},
	error: {
		color: theme.palette.error.main,
	},
	text: {
		paddingLeft: '14px',
	},
}));

const WithdrawModuleButton = () => {
	const classes = useStyles();
    const dispatch = useDispatch()
    const showModal = () => dispatch(app.actions.showBaseModal(ModalPopups.COMMON_WITHDRAW_MODULE_MODAL));

	return (
		<div className={classes.root} onClick={showModal}>
            <SvgIcon icon="Withdraw" />
            <div
                className={classes.text}
            >
                <R id="shared_withdraw" defaultValue="Withdraw" />
            </div>
		</div>
	);
};

export default WithdrawModuleButton;
