import { NotificationsContextProvider, useNotificationsContext } from '@dev2t/react-ui-kit/src';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@redux';
import { constants, env } from '@config';

// чтобы не тащить саги в компонент, он принимает коллбэки на те действия, которые не может сделать сам.
// однако, приложение работает на сагах, поэтому вот так немного странно реализуются коллбэки
const useNotificationLoadingBySaga = () => {
    const resolveRef = useRef(null);
    const lastLoadedNotifications = useSelector(notifications.selectors.lastLoadedNotifications);
    const dispatch = useDispatch();

    useEffect(() => {
        if (lastLoadedNotifications?.paginationState?.page == null) return;

		resolveRef.current?.(lastLoadedNotifications)
        resolveRef.current = null;
    }, [lastLoadedNotifications?.paginationState])

    const callback = useCallback(
       (page, pageSize) => new Promise(resolve => {
           resolveRef.current = resolve;
           dispatch(notifications.actions.getNotifications.request({ page: page, pageSize }))
       }), [dispatch]);

    // для NSR отключены уведомления
    return env.firm !== constants.LIME_NSR && callback;
}

const useBannersLoadingBySaga = () => {
    const resolveRef = useRef(null);
    const banners = useSelector(notifications.selectors.banners);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!banners) return;

		resolveRef.current?.(banners)
        resolveRef.current = null;
    }, [banners])

    const callback = useCallback(
       () => new Promise(resolve => {
           resolveRef.current = resolve;
           dispatch(notifications.actions.getBanners.request())
       }), [dispatch]);

    // для NSR отключены уведомления
    return env.firm !== constants.LIME_NSR && callback;
}

const useShowNotification = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(notifications.selectors.messageIsOpen);
    const resolveRef = useRef(null);

    useEffect(() => {
        if (isOpen) return;
        
		resolveRef.current?.()
        resolveRef.current = null;
    }, [isOpen])

    return useCallback(() => {
        dispatch(notifications.actions.showMessageModal());
        return new Promise(r => resolveRef.current = r);
    }, [dispatch])
}

// хук с получением контекста должен быть внутри контекст провайдера, 
// поэтому пришлось сделать еще одним враппером
const NotificationsActionsSaveToStoreWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const { actions: { forceReloadNotifications} } = useNotificationsContext();

    useEffect(() => {
        dispatch(notifications.actions.reloadNotifications.setHandler(forceReloadNotifications))
    }, [forceReloadNotifications])

    return children;
}

const NotificationsContextWrapper = ({ children }) => {
    const loadMore = useNotificationLoadingBySaga();
    const loadBanners = useBannersLoadingBySaga();
    const showNotification = useShowNotification();

    return (
        <NotificationsContextProvider 
            displayedByDefaultOnLargeScreen
            loadMore={loadMore}
            showNotificationAndWaitUntilClosing={showNotification}
            loadBanners={loadBanners}
        >
            <NotificationsActionsSaveToStoreWrapper>
                {children}
            </NotificationsActionsSaveToStoreWrapper>
        </NotificationsContextProvider>
    )
}

export default NotificationsContextWrapper;