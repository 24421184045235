import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { userAccounts, depositFunds } from '@redux';
import { ProgressContainer } from '@components';
import { Modal, ErrorMessage } from '@dev2t/react-ui-kit/src/components';
import { connect } from 'react-redux';
import { DepositFundsForm } from './components';

const styles = {};

const mapStateToProps = (state) => {
	const depositAchModal = depositFunds.selectors.depositAchModal(state);
	if (depositAchModal.bankAccount && depositAchModal.tradeAccount) {
		return {
			depositAchModal,
			canShow: true,
			loading: false,
		};
	}
	// default if not set
	const loading = userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading;
	const tradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const linkedAccounts = userAccounts.selectors.linkedAccounts(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);
	const bankAccount = loading ? null : linkedAccounts.find(x => x.Type === 'ACH');
	return {
		depositAchModal: {
			bankAccount,
			tradeAccount,
		},
		canShow: !!(accountStatus && accountStatus.CanFund && accountStatus.CanAchLink),
		loading,
	};
};

class DepositFundsAchModal extends React.Component {
	render() {
		const {
			canShow,
			loading,
			onClose,
			depositAchModal,
		} = this.props;

		return (
			<Modal
				title="Deposit"
				onClose={onClose}
				className={`QA-${ModalPopups.DEPOSIT_FUNDS_ACH}`}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{canShow && !depositAchModal.bankAccount &&
							<ErrorMessage onClick={onClose} text={`Account ${depositAchModal.tradeAccount.tradeCode} is not linked.`} />
						}
						{!canShow &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${depositAchModal.tradeAccount.tradeCode}.`} />
						}
						{depositAchModal.bankAccount && canShow &&
							<DepositFundsForm
								bankAccount={depositAchModal.bankAccount}
								tradeAccount={depositAchModal.tradeAccount}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

DepositFundsAchModal.propTypes = {
	canShow: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	depositAchModal: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(DepositFundsAchModal);
